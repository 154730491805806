<template lang="pug">
    include ../../mixins/mixins.pug
    div
        .mb-5
            .container
                .row.page-title-container
                    .col-md
                        h1.page-title-no-margin(v-if="$route.name === 'update-plan'")
                            | Update Subscription
                        h1.page-title-no-margin(v-if="$route.name === 'update-payment-info'")
                            | Payment Info Update
                    .col-md.align-self-center
                        usage-indicator.my-3.my-md-0
                response-container(:success="success", :error="error", v-if="!Billing")
                .text-center
                    router-link.btn.btn-light-gray.mb-4(to="/billing") Back to Billing
                div(v-if="Billing && $route.name === 'update-plan'")
                    response-container(:success="success", :error="error", v-if="!newPlan")
                    pricing-view(@planselect="selectPlan", v-if="!newPlan", :current-plan="Billing.Plan", :cancelled="Billing.Cancelled", :trial-eligible="Billing.TrialEligible", :billing-details="Billing")
                    div(v-if="newPlan")
                        .row.justify-content-center.mb-5
                            .col-lg-6.col-md-4
                                .d-flex.justify-content-center(v-if="newPlan === Billing.Plan")
                                    +planDisplay('Plan', true)(v-if="newPlan === plan.stripeName").new-plan
                                .d-flex.justify-content-center(v-else)
                                    +planDisplay('Current Plan')(v-if="Billing.Plan === plan.stripeName")
                                    +planDisplay('New Plan', true)(v-if="newPlan === plan.stripeName").new-plan
                        .row.justify-content-center
                            .col-auto
                                stripe-payment-details.mb-5.py-0(v-if="Billing.PaymentMethod", :payment-method="Billing.PaymentMethod")
                        .row.justify-content-center
                            .col-md-6
                                form(v-on:submit.prevent="updateBilling", v-if="Billing")
                                    div(v-if="newPlan !== 'Free'")
                                        .text-center.mb-4(v-if="!(Billing.TrialEnd && !Billing.TrialEligible) || Billing.PaymentMethod")
                                            button.btn.btn-light-gray(v-if="!updatePaymentInfo && Billing.PaymentMethod", v-on:click="updatePaymentInfo = true", type="button") Update Payment Info
                                            button.btn.btn-light-gray(v-if="updatePaymentInfo", v-on:click="updatePaymentInfo = false", type="button") Keep Current Payment Info
                                        .text-center.mb-4(v-if="(Billing.TrialEnd || Billing.TrialEligible) && !Billing.PaymentMethod")
                                            button.btn.btn-light-gray(v-if="!updatePaymentInfo", v-on:click="updatePaymentInfo = true", type="button") Enter Payment Info
                                            button.btn.btn-light-gray(v-if="updatePaymentInfo", v-on:click="updatePaymentInfo = false", type="button") Continue w/o Payment Info
                                        collect-stripe-payment-details.mb-4(ref="stripeController", v-if="updatePaymentInfo || (!Billing.PaymentMethod && !Billing.TrialEnd && !Billing.TrialEligible)")
                                    response-container(:success="success", :error="error")
                                    .alert.alert-info(v-if="newPlan === 'Free'")
                                        p We're sorry to see you go. In order to help us improve our service, please tell us the reason for your cancellation.
                                        .form-group.mb-0
                                            input.form-control(type="text", v-model="CancellationReason", placeholder="Reason for cancellation...")
                                    p(v-html="buttonMessage")
                                    .text-center
                                        button.btn(type="submit", v-button-loading="updating", v-bind:class="{'btn-primary-green': buttonLabel !== 'Unsubscribe', 'btn-danger': buttonLabel === 'Unsubscribe'}")
                                            | {{buttonLabel}}
                div(v-if="Billing && $route.name === 'update-payment-info'")
                    .row.justify-content-center
                        .col-md-6
                            .row.justify-content-center
                                .col-auto
                                    stripe-payment-details.mb-4(v-if="Billing.PaymentMethod", :payment-method="Billing.PaymentMethod")
                            form(v-on:submit.prevent="updateBilling", v-if="Billing")
                                collect-stripe-payment-details(ref="stripeController")
                                response-container(:success="success", :error="error")
                                .text-center
                                    button.btn.btn-primary-green(type="submit", v-button-loading="updating")
                                        | Update
</template>

<script>
    import ResponseContainer from '@/components/ResponseContainer';
    import UsageIndicator from '@/components/UsageIndicator';
    import PricingView from '@/components/PricingView';
    import StripePaymentDetails from '@/components/StripePaymentDetails';
    import CollectStripePaymentDetails from '@/components/CollectStripePaymentDetails';

    export default {
        metaInfo: {
            title: 'Update Billing'
        },
        components: {
            ResponseContainer,
            UsageIndicator,
            PricingView,
            StripePaymentDetails,
            CollectStripePaymentDetails,
        },
        created () {
            if (this.$root.requireAuth()) return;
            this.getBilling();

            if (this.$route.name === 'update-plan' && this.$route.query.plan && this.plans.filter(p => p.name === this.$route.query.plan).length > 0) {
                this.newPlan = this.$route.query.plan;
            }
        },
        computed: {
            plans () {
                return this.$store.state.plans.plans;
            },
            newPlanPlan () {
                for (let i = 0; i < this.plans.length; i++) {
                    if (this.plans[i].stripeName === this.newPlan) {
                        return this.plans[i];
                    }
                }
                return null;
            },
            currentPlanPlan () {
                for (let i = 0; i < this.plans.length; i++) {
                    if (this.plans[i].stripeName === this.Billing.Plan) {
                        return this.plans[i];
                    }
                }
                return null;
            },
            buttonLabel () {
                if (!this.newPlanPlan || !this.currentPlanPlan) return null;
                if (this.currentPlanPlan.stripeName === 'Free') {
                    return 'Subscribe';
                } else if (this.newPlanPlan.stripeName === 'Free') {
                    return 'Unsubscribe';
                } else if (this.newPlanPlan.stripeName === this.currentPlanPlan.stripeName) {
                    return 'Resubscribe'
                } else {
                    return this.currentPlanPlan.price < this.newPlanPlan.price ? 'Upgrade' : 'Downgrade';
                }
            },
            buttonMessage () {
                if (!this.newPlanPlan || !this.currentPlanPlan) return null;
                if (this.buttonLabel === 'Upgrade') {
                    return `By clicking '${this.buttonLabel}' you will be subscribed to a new dealr.tax plan and ${this.Billing.TrialEnd ? 'be charged the subscription amount at the end of your trial.' : `immediately be charged ${this.currentPlanPlan.stripeName === 'Free' ? 'the' : 'a prorated'} subscription fee.`}`;
                } else if (this.buttonLabel === 'Downgrade') {
                    return `By clicking '${this.buttonLabel}' you will be subscribed to a new dealr.tax plan and ${this.Billing.TrialEnd ? 'be charged the subscription amount at the end of your trial.' : 'will receive account credit for your previous plan and be charged any overage amounts accrued during your subscription.'}`;
                } else if (this.buttonLabel === 'Unsubscribe') {
                    return `By clicking '${this.buttonLabel}' ${this.Billing.TrialEnd ? 'your account will revert to a Free plan at the end of your trial. Your card will not be charged.' : 'your subscription will be cancelled at the end of your current billing period.'}`;
                } else if (this.buttonLabel === 'Resubscribe') {
                    return `By clicking '${this.buttonLabel}' ${this.Billing.TrialEnd ? 'you will be charged the subscription amount at the end of your trial.' : 'your subscription will be resumed.'}`;
                } else if (this.buttonLabel === 'Subscribe') {
                    return `By clicking '${this.buttonLabel}' you will be subscribed to dealr.tax and ${this.Billing.TrialEligible ? `start a 14-day free trial. <br> ${this.enterBillingDetails ? 'Your card will be charged monthly starting at the end of the trial.' : 'If you don\'t enter your payment details by the end of the trial your account will be reverted to the Free plan.'}` : 'be charged the subscription fee.'}`;
                } else {
                    return null;
                }
            },
        },
        data () {
            return {
                Billing: null,
                CancellationReason: null,
                updating: false,
                success: null,
                error: null,
                newPlan: null,
                updatePaymentInfo: false,
            }
        },
        methods: {
            selectPlan (planName) {
                this.newPlan = planName;
            },
            async getBilling () {
                try {
                    const response = await this.$root.axios.get('company/billing');
                    this.Billing = response.data;
                } catch (e) {
                    this.error = e.response.data;
                }
            },
            async updateBilling () {
                this.updating = true;
                this.error = false;
                let token;
                if ((this.newPlan !== 'Free' && !this.Billing.PaymentMethod && !this.Billing.TrialEnd && !this.Billing.TrialEligible) || this.updatePaymentInfo || this.$route.name === 'update-payment-info') {
                    if (!this.$refs.stripeController) {
                        this.error = 'We weren\'t able to save your card details.';
                        this.updating = false;
                        return false;
                    }
                    token = await this.$refs.stripeController.getStripeToken();
                    if (token === false) {
                        this.updating = false;
                        return false;
                    }
                }
                try {
                    const request = {};
                    if (token) {
                        request.StripeToken = token;
                    }
                    if (this.newPlan) {
                        request.Plan = this.newPlan;
                    }
                    if (this.newPlan === 'Free') {
                        request.CancellationReason = this.CancellationReason;
                    }
                    const result = await this.$root.axios.put('company/billing', request);
                    this.$router.push({ name: 'billing' })
                    this.updating = false;
                } catch (e) {
                    this.updating = false;
                    if (e.response) {
                        this.error = e.response.data;
                    } else {
                        this.error = 'There was an internal server error. Please try again.';
                    }
                    // if(e.response.status === 401) this.Password = '';
                }
            },
        }
    }
</script>

<style lang="scss">
    @import '../../scss/Main';
</style>
