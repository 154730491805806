<template lang="pug">
    .container.my-5.api-docs
         //include:highlight-markdown-it() Docs.md
         include:markdown-it(html=true) Docs.md
</template>

<script>
    export default {
        metaInfo: {
            title: 'API Documentation',
            meta: [
                {
                    name: 'description',
                    content: 'The dealr.tax API is super easy to use and access with a REST endpoint and a free trial!'
                }
            ],
        },
    }
</script>

<style lang="scss">
    @import '../../scss/Main';
    .api-docs {
        h1, h2, h3, h4, h5, h6 {
            margin-bottom: $headings-margin-bottom * 3;
        }
        table {
            width: 100%;
            td, th {
                border: $table-border-width solid $table-border-color;
                padding: $table-cell-padding-sm $table-cell-padding-sm*2;
            }
            margin-bottom: 2rem;
        }
        code {
            font-size: 85%;
            background-color: $gray-200;
            padding: .1rem .5rem;
            border-radius: .25rem;
        }
        pre {
            background-color: $gray-200;
            border-radius: .5rem 1rem 1rem .5rem;
            padding: 1rem 2rem;
            border-left: .5rem solid $primary-color;
            font-size: 85%;
            code {
                background: transparent;
                font-size: inherit;
            }
        }
    }
</style>
