<template lang="pug">
    -
        function camelize(str) {
            return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
                if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
                return index == 0 ? match.toLowerCase() : match.toUpperCase();
            });
        };
    mixin input(title, model, attr)
        .form-group&attributes(attributes)
            label(for=camelize(title)) #{title}
            input.form-control(type="text", id=camelize(title), name=camelize(title), v-model=model)&attributes(attr)
    div
        .mb-5
            .container
                h1.page-title
                    | SETTINGS
                response-container(:success="success", :error="error")
                form(v-on:submit.prevent="updateCompany", v-if="Company")
                    .row
                        .col-lg-6.col-md-4
                            +input('Name', 'Company.Name')
                            +input('Billing Email', 'Company.BillingEmail')
                            b-form-checkbox(v-model="Company.Settings.BuildingUse") Show building use tax.
                            +input('Dealer Number', 'Company.Settings.DealerNumber').mt-3
                            +input('Colorado Sales Tax Number', 'Company.Settings.ColoradoTaxNumber')
                            +input('City Sales Tax Number', 'Company.Settings.CityTaxNumber')
                        .col-lg-6.col-md-8
                            address-input(:address="Company.Address", address-label="Physical Address")
                    button.btn.btn-secondary.float-right(v-button-loading="updating", type="submit") Update
</template>

<script>
    import ResponseContainer from '@/components/ResponseContainer';
    import AddressInput from '@/components/AddressInput';
    import { BFormCheckbox as bFormCheckbox } from 'bootstrap-vue'

    export default {
        metaInfo: {
            title: 'Company Settings'
        },
        components: {
            ResponseContainer,
            AddressInput,
            bFormCheckbox,
        },
        created () {
            if (this.$root.requireAuth()) return;
            this.getCompany();
        },
        data () {
            return {
                Company: null,
                updating: false,
                success: null,
                error: null,
            }
        },
        methods: {
            async getCompany () {
                try {
                    const response = await this.$root.axios.get('company/');
                    this.Company = response.data;
                } catch (e) {
                    this.error = e.response.data;
                }
            },
            async updateCompany () {
                if (this.updating !== false) return;
                this.updating = true;
                this.success = null;
                this.error = null;
                try {
                    const result = await this.$root.axios.put('company', this.Company);
                    this.success = 'Company details updated.';
                    this.updating = false;
                } catch (e) {
                    this.updating = false;
                    this.error = e.response.data;
                    // if(e.response.status === 401) this.Password = '';
                }
            },
        }
    }
</script>

<style scoped>

</style>
