<template lang="pug">
    .mb-5
        .container
            .text-center
                h1.mb-2.page-title
                    | Pricing
                div(v-if="trialEligible")
                    h4.mb-0.text-secondary Try for free for 14 days
                    h6.text-uppercase.text-secondary No credit card required
            pricing-view(v-if="Billing", :pricing-page="true", :current-plan="Billing.Plan", :cancelled="Billing.Cancelled", :trial-eligible="Billing.TrialEligible", :billing-details="Billing")
            pricing-view(v-if="!Billing", :pricing-page="true", :trial-eligible="trialEligible")
</template>

<script>
    import PricingView from '@/components/PricingView';
    export default {
        metaInfo: {
            title: 'Lookup Pricing',
            meta: [
                {
                    name: 'description',
                    content: 'Try dealr.tax free for 14 days and see out how easy it is to determine taxes by address!'
                }
            ],
        },
        components: {
            PricingView
        },
        data () {
            return {
                trialEligible: false,
                Billing: null,
            };
        },
        created () {
            if (this.$store.state.auth.authenticated && this.$store.state.auth.plan !== 'Free') {
                return this.$router.push({ name: 'home' })
            }
            if (this.$store.state.auth.authenticated) {
                this.getBilling();
            }
        },
        methods: {
            async getBilling () {
                try {
                    const response = await this.$root.axios.get('company/billing');
                    this.Billing = response.data;
                } catch (e) {
                    this.error = e.response.data;
                }
            },
        },
    }
</script>
