<template lang="pug">
    .container.mb-5.text-center
        h1.page-title.title-404 404
        h5 Whoops. Couldn't find that one.
        p
            router-link.d-block(to="/") Head Home
            router-link.mt-3.btn.btn-primary-green(to="/lookup") Rate Lookup
</template>

<script>
    export default {
        metaInfo: {
            title: 'Page Not Found'
        },
    }
</script>

<style lang="scss">
    @import '../scss/Main.scss';
    .title-404 {
        font-size: $font-size-base * 7
    }
</style>
