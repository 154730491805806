<template lang="pug">
    include ../../mixins/mixins.pug
    div
        .mb-5
            .container
                .row.page-title-container
                    .col-md
                        h1.page-title-no-margin
                            | Billing
                    .col-md.align-self-center
                        usage-indicator.my-3.my-md-0
                response-container(:success="success", :error="error")
                form(v-on:submit.prevent="updateBilling", v-if="Billing")
                    .row
                        .col-md-4
                            +planDisplay('Plan')(v-if="Billing.Plan === plan.stripeName").p-0.mb-3
                            .uppercase-label.mb-4(v-if="Billing.TrialEnd") Trial ends on {{Billing.TrialEnd | moment('M/D/YYYY')}}
                            router-link.btn.btn-primary-green(to="/update-plan")
                                span(v-if="Billing.Plan === 'Free'")
                                    span(v-if="Billing.TrialEligible")
                                        | Try Free
                                    span(v-else)
                                        | Upgrade
                                span(v-else-if="Billing.Cancelled && Billing.PaymentMethod") Resubscribe
                                span(v-else)
                                    | Change Plan
                        .col-md-4
                            div(v-if="Billing.Cancelled")
                                .alert.alert-danger
                                    div(v-if="Billing.TrialEnd")
                                        h5(v-if="!Billing.PaymentMethod") Missing Payment Info
                                        h5(v-else) Subscription Cancelled
                                        p Your subscription will revert to a free plan at the end of your trial.
                                        .text-center
                                            router-link.btn.btn-primary-green(v-if="!Billing.PaymentMethod", to="/update-payment-info") Enter Payment Info
                                            router-link.btn.btn-primary-green(v-else, to="/update-plan") Resubscribe
                                    div(v-else)
                                        h5 Subscription Cancelled
                                        p Your subscription was cancelled on {{Billing.CancelledAt | moment('M/D/YYYY')}}. Click below to resubscribe.
                                        .text-center
                                            router-link.btn.btn-primary-green(to="/update-plan") Resubscribe
                                        hr
                                        p Your subscription will revert to a free plan at the end of your current billing period.
                            div(v-if="Billing.PaymentMethod")
                                stripe-payment-details.pt-0(:payment-method="Billing.PaymentMethod")
                                router-link.btn.btn-primary-green(to="/update-payment-info")
                                    | Update Payment Info
                            div(v-if="!Billing.PaymentMethod && (!Billing.Cancelled || !Billing.TrialEnd)")
                                router-link.btn.btn-primary-green(to="/update-payment-info")
                                    | Enter Payment Info
                            .alert.alert-danger.mt-3(v-if="Billing.Delinquent")
                                | #[h5 Failed Payment]We haven't been paid on your most recent bill.
                                |
                                span(v-if="Billing.Plan !== 'Free'")
                                    |Your subscription will be cancelled if we don't receive payment.
                                span(v-else)
                                    |Your subscription has been cancelled until you update your payment info and resubscribe.
                        .col-md-4
                            div(v-if="Billing.CurrentPeriodEnd")
                                .uppercase-label Current Billing Period
                                | {{Billing.CurrentPeriodStart | moment('M/D/YYYY')}} - {{Billing.CurrentPeriodEnd | moment('M/D/YYYY')}}
                            div(v-if="Billing.UpcomingInvoiceTotal")
                                .uppercase-label.mt-4 Next Bill
                                div #[strong {{(Billing.UpcomingInvoiceTotal/100)|formatPrice}}] on {{Billing.UpcomingInvoiceDate | moment('M/D/YYYY')}}
                                small Not all recent usage is being included in this amount.
                            div(v-if="Billing.AccountBalance", v-bind:class="{'alert alert-danger': Billing.AccountBalance > 50}").mt-4
                                .uppercase-label Account Balance
                                div #[strong {{(Billing.AccountBalance/100)|formatPrice}}]
                                small(v-if="Billing.AccountBalance < 0") This balance will be credited against your next invoice.
                                small(v-if="Billing.AccountBalance < 50 && Billing.AccountBalance > 0") This balance will be added to your next invoice.
                                small(v-if="Billing.AccountBalance >= 50") This balance is unpaid.
                            div.mt-4(v-if="Invoices.length")
                                .uppercase-label Invoices
                                ul.list-unstyled.invoice-list.mt-3
                                    li.invoice-item(v-for="invoice in Invoices", v-bind:class="{'alert alert-danger': invoice.due > 0 && invoice.attempted }")
                                        .row
                                            .col.font-weight-bold
                                                .badge.badge-danger.mr-2(v-if="invoice.Due > 0") DUE
                                                | {{invoice.Number}}
                                            .col-auto {{invoice.Date | moment('M/D/YYYY')}}
                                        .row
                                            .col(v-if="!invoice.AccountCredited")
                                                | {{invoice.Amount/100|formatPrice}}
                                            .col(v-else)
                                                | Account Credited
                                            .col-auto
                                                a(v-bind:href="invoice.Url", target="_blank") View
                                small(v-if="Invoices.length && Invoices.length === 10") You're 10 most recent invoices are shown here.
</template>

<script>
    import ResponseContainer from '@/components/ResponseContainer';
    import UsageIndicator from '@/components/UsageIndicator';
    import StripePaymentDetails from '@/components/StripePaymentDetails';

    export default {
        metaInfo: {
            title: 'Billing'
        },
        components: {
            ResponseContainer,
            UsageIndicator,
            StripePaymentDetails,
        },
        computed: {
            plans () {
                return this.$store.state.plans.plans;
            },
        },
        created () {
            if (this.$root.requireAuth()) return;
            this.getBilling();
            this.getInvoices();
        },
        data () {
            return {
                Billing: null,
                Invoices: [],
                updating: false,
                success: null,
                error: null,
            }
        },
        methods: {
            async getBilling () {
                try {
                    const response = await this.$root.axios.get('company/billing');
                    this.Billing = response.data;
                } catch (e) {
                    this.error = e.response.data;
                }
            },
            async getInvoices () {
                try {
                    const response = await this.$root.axios.get('company/billing/invoices');
                    this.Invoices = response.data;
                } catch (e) {
                    this.error = e.response.data;
                }
            },
        }
    }
</script>

<style lang="scss">
    @import '../../scss/Main';
    .invoice-item {
        padding: .5rem 1rem;
        &:nth-child(odd) {
            background-color: $gray-200;
        }
    }
</style>
