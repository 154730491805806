<template lang="pug">
    -
        const userMenu = [
        {
        name: 'Profile',
        router: true,
        attributes: {
        to: '/profile'
        }
        },
        {
        name: 'Users',
        router: true,
        attributes: {
        to: '/users',
        'v-if': 'Auth.planDetails && Auth.planDetails.MultiUser && Auth.permissions.Admin'
        }
        },
        {
            name: 'Settings',
            router: true,
            attributes: {
            to: '/settings',
            'v-if': 'Auth.permissions.Admin'
        }
        },
        {
            name: 'Billing',
            router: true,
            attributes: {
            to: '/billing',
            'v-if': 'Auth.permissions.Admin'
            }
        },
        {
            name: 'Logout',
            attributes: {
            'v-on:click.prevent': '$root.logout',
            href: '#'
            }
        }
        ]
    mixin loginView
        b-dropdown.nav-link.mx-auto.mx-md-0.d-none.d-md-block(v-if="Auth.authenticated", no-caret, variant="link", right, )
            template(slot="button-content")
                a.nav-link.navbar-login-view.d-flex.align-items-center.justify-content-center
                    .navbar-login-view-icon
                        .navbar-login-view-initials-container {{Auth.user.Initials}}
                    .navbar-login-view-text.position-relative
                        .d-block.navbar-login-view-name.text-nowrap.text-left {{Auth.user.Name}}
                        .d-block.navbar-login-view-companytext-container.position-relative
                            .position-absolute.d-block.w-100.navbar-login-view-companytext.text-uppercase.font-weight-bold.text-truncate {{Auth.company.Name}}
                            .position-absolute.d-block.w-100.navbar-login-view-logintext.text-uppercase.font-weight-bold.text-truncate
                                | SETTINGS
                                font-awesome-icon(:icon="['fas','chevron-down']", fixed-width)
            each item in userMenu
                b-dropdown-item&attributes(item.attributes) #{item.name}
        .mobile-login-view-menu.py-4.mt-4(v-if="Auth.authenticated")
            a.nav-link.navbar-login-view.d-flex.d-md-none.align-items-center.justify-content-center.no-hover-animate
                .navbar-login-view-icon
                    .navbar-login-view-initials-container {{Auth.user.Initials}}
                .navbar-login-view-text.position-relative
                    .d-block.navbar-login-view-name.text-nowrap.text-left {{Auth.user.Name}}
                    .d-block.navbar-login-view-companytext-container.position-relative
                        .position-absolute.d-block.w-100.navbar-login-view-companytext.text-uppercase.font-weight-bold.text-truncate {{Auth.company.Name}}
                        .position-absolute.d-block.w-100.navbar-login-view-logintext.text-uppercase.font-weight-bold.text-truncate
                            | SETTINGS
                            font-awesome-icon(:icon="['fas','chevron-down']", fixed-width)
            ul.navbar-nav.ml-auto.d-md-none.py-0
                each item in userMenu
                    li.nav-item
                        if item.router
                            router-link.nav-link&attributes(item.attributes) #{item.name}
                        else
                            a.nav-link&attributes(item.attributes) #{item.name}
    mixin tryFreeButton
        router-link.get-started-button.btn.btn-sm.btn-light(dealr-track-click='Navbar,GetStartedButton|ButtonInteractions,NavbarInteraction|ButtonInteractions,TryForFree', to='try-free', v-if="!Auth.authenticated")
            | Try For Free
            font-awesome-icon.ml-1(:icon="['fas','arrow-alt-right']", fixed-width)
    mixin loginButton
        router-link.nav-link(dealr-track-click='Navbar,LogInLink', to='/login', v-if="!Auth.authenticated")
            font-awesome-icon(:icon="['fas','building']", fixed-width)
            |  Login
    nav#nav-main.navbar.navbar-expand-md.fixed-top(v-bind:class="{'dark-bg': pageDarkBG && !scrolled}")
        .container
            router-link.navbar-brand(dealr-track-click='Navbar,HomeLogoLink|ButtonInteractions,NavbarInteraction', to='/')
                .navbar-logo-container
                    img.nav(src='../assets/logo-white.svg', height='100%', alt='dealr.tax Logo')
            button.navbar-toggler.hamburger.hamburger--slider-r(dealr-track-click='Navbar,MobileMenuOpen', type='button', data-toggle='collapse', data-target='#navbarSupportedContent', aria-controls='navbarSupportedContent', aria-expanded='false', aria-label='Menu')
                span.hamburger-box
                    span.hamburger-inner
            #navbarSupportedContent.collapse.navbar-collapse
                .navbar.navbar-expand-md.fixed-top
                    .container
                        router-link.navbar-brand(to='/')
                            .navbar-logo-container
                                img(src='../assets/logo-white.svg', height='100%', alt='dealr.tax Logo')
                        button.navbar-toggler.hamburger.hamburger--slider-r(type='button', data-toggle='collapse', data-target='#navbarSupportedContent', aria-controls='navbarSupportedContent', aria-expanded='false', aria-label='Menu')
                            span.hamburger-box
                                span.hamburger-inner
                ul.navbar-nav.ml-auto.align-items-center
                    li.nav-item
                        router-link.nav-link(dealr-track-click='Navbar,PricingLink|ButtonInteractions,NavbarInteraction', to='/pricing', v-if="!Auth.authenticated || !Auth.plan || Auth.plan === 'Free'") Pricing
                    li.nav-item
                        router-link.nav-link(dealr-track-click='Navbar,RateLookup|ButtonInteractions,NavbarInteraction', to='/lookup') Rate Lookup
                    li.nav-item
                        router-link.nav-link(dealr-track-click='Navbar,RateLookup|ButtonInteractions,NavbarInteraction', to='/lookups', v-if="Auth.authenticated && Auth.planDetails && Auth.planDetails.History") Lookup History
                    li.nav-item.ml-5.mr-5.d-none.d-lg-block

                    li.nav-item.d-md-none
                        +loginButton
                    li.nav-item.text-center.d-md-none.position-relative
                        +loginView
                        +tryFreeButton
                    li.d-none.d-md-flex.nav-item.text-center.text-lg-left.justify-content-start.align-items-center.position-relative
                        +loginView
                        +loginButton.mr-lg-3
                        +tryFreeButton
</template>

<script>
    import $ from 'jquery'
    import { BDropdown as bDropdown, BDropdownItem as bDropdownItem } from 'bootstrap-vue'
    import { mapGetters, mapState } from 'vuex'
    export default {
        components: {
            bDropdown,
            bDropdownItem,
        },
        created () {
            this.interval = setInterval(() => { this.handleScroll(); }, 250);
        },
        destroyed () {
            clearInterval(this.interval);
        },
        data () {
            return {
                interval: null,
                scrolled: false,
            };
        },
        methods: {
            handleScroll (event) {
                if (this.scrolled && window.pageYOffset < 5) {
                    this.scrolled = false;
                } else if (!this.scrolled && window.pageYOffset >= 5) {
                    this.scrolled = true;
                }
            },
        },
        mounted () {
            $(document).ready(() => {
                $('#navbarSupportedContent').on('show.bs.collapse', function () {
                    $(this).addClass('show-menu');
                    $('body').addClass('mobile-nav-open');
                });
                $('#navbarSupportedContent').on('hide.bs.collapse', function () {
                    $(this).removeClass('show-menu');
                    $('body').removeClass('mobile-nav-open');
                });
            })
        },
        watch: {
            $route () {
                this.handleScroll();
                $('#navbarSupportedContent').collapse('hide')
            },
        },
        computed: {
            Auth () {
                return this.$store.getters['auth/auth'];
            },
            pageDarkBG () {
                return this.$route.meta.pageDarkBg;
            },
        }
    }
</script>

<style lang="scss" src="../scss/Navbar.scss"></style>
