<template lang="pug">
    -
        function camelize(str) {
            return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
                if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
                return index == 0 ? match.toLowerCase() : match.toUpperCase();
            });
        };
    mixin input(title, model, attr)
        .form-group&attributes(attributes)
            label(for=camelize(title)) #{title}
            input.form-control(type="text", id=camelize(title), name=camelize(title), v-model=model)&attributes(attr)
    mixin passwordSection
        +input('New Password', 'PasswordUpdate.NewPassword', {type: 'password'})
        ul
            li(v-for="(valid, string) in Validators", v-bind:class="{'text-danger': !valid, 'text-success': valid}")
                | {{string}}
        +input('Confirm Password', 'PasswordUpdate.ConfirmPassword', {type: 'password'})
    div
        .mb-5
            .container
                h1.page-title
                    | USER
                response-container(:success="success", :error="error")
                .row(v-if="User")
                    .col-md-4
                        h4(v-if="User.IsMaster")
                            .badge.badge-primary Master
                        h4(v-if="User.IsApiUser && User.Id")
                            .badge.badge-primary API User
                        form(v-on:submit.prevent="updateUser")
                            .form-group
                                b-form-checkbox(v-model="User.IsActive", v-bind:disabled="$store.state.auth.user.Id === User.Id").mb-3 Active
                            .form-group
                                b-form-checkbox(v-if="!User.Id && $store.state.auth.planDetails.ApiAccess", v-model="User.IsApiUser").mb-3 API Key
                            div(v-if="!User.IsApiUser")
                                div(v-if="!User.Id")
                                    +input('Email', 'User.Email', {'@keyup': 'checkEmailUsernameTaken', '@change': 'checkEmailUsernameTaken'})
                                    div(v-if="EmailUsernameTaken")
                                        .text-danger.mb-2 This email has been taken as a username. Please enter another username for login.
                                        +input('Username', 'User.UserName', {'@keyup': 'checkUsernameTaken', '@change':'checkUsernameTaken'})
                                        .text-danger.mt-2(v-if="UsernameTaken") This username has been taken.
                                    +passwordSection
                                div(v-else)
                                    +input('Email', 'User.Email')
                                    +input('Username', 'User.UserName', {disabled: true})(v-if="User.UserName !== OrigUser.Email")
                                .form-group
                                    label(for="roleSelect") Role
                                    b-form-select#roleSelect(v-model="User.RoleId", :options="Roles", v-bind:disabled="User.Id && $store.state.auth.user.Id === User.Id")
                                +input('First Name', 'User.NameFirst')
                                +input('Last Name', 'User.NameLast')
                                .form-group
                                    label(for="userQuota") User Quota (0 is unlimited)
                                    vue-numeric#userQuota.form-control(type="text", minus=false, :empty-value="0", currency="", :precision=0, v-model="User.Quota")
                            div(v-else)
                                +input('Key Name', 'User.NameFirst')
                                .form-group
                                    label(for="userQuota") Key Quota (0 is unlimited)
                                    vue-numeric#userQuota.form-control(type="text", minus=false, :empty-value="0", currency="", :precision=0, v-model="User.Quota")
                                +input('API Token', 'User.UserName')(v-if="User.Id")
                                +input('API Secret', 'User.ApiKey')(v-if="User.Id")
                            button.btn.btn-secondary(v-button-loading="updating", type="submit") {{User.Id ? 'Update' : 'Create'}}
                    .col-md-4(v-if="User.Id && !User.IsApiUser")
                        button.btn.btn-secondary(v-if="!changePassword && $store.state.auth.user.Id !== User.Id", v-on:click="changePassword = true") Change Password
                        p(v-else) In order to change your own password click your name in the top right corner and select "Profile."
                        div(v-if="changePassword")
                            h5 CHANGE PASSWORD
                            form(v-on:submit.prevent="updatePassword")
                                +passwordSection
                                button.btn.btn-secondary(v-button-loading="changingPassword", type="submit") Update Password
</template>

<script>
    import VueNumeric from '@/components/VueNumeric'
    import ResponseContainer from '@/components/ResponseContainer';
    import { BFormCheckbox as bFormCheckbox, BFormSelect as bFormSelect } from 'bootstrap-vue'
    import _ from 'lodash';

    export default {
        metaInfo () {
            return {
                title: this.pageTitle,
            }
        },
        components: {
            ResponseContainer,
            bFormCheckbox,
            bFormSelect,
            VueNumeric,
        },
        name: 'user',
        mounted () {
            if (this.$root.requireAuth()) return;
            this.getUser();
            this.getRoles();
        },
        computed: {
            pageTitle () {
                if (this.User && !this.User.Id) return 'Add User';
                if (!this.User || !this.User.Name) return 'Edit User';
                return `Edit ${this.User.Name}`;
            },
        },
        data () {
            return {
                User: null,
                OrigUser: null,
                Roles: [],
                updating: false,
                changePassword: false,
                changingPassword: false,
                PasswordUpdate: {},
                Validators: {
                    'Contains a number.': false,
                    'Contains a letter.': false,
                    '8 characters long.': false,
                },
                success: null,
                error: null,
                EmailUsernameTaken: false,
                UsernameTaken: false,
            }
        },
        watch: {
            'PasswordUpdate.NewPassword' (newPass, oldPass) {
                this.Validators['8 characters long.'] = newPass.length >= 8;
                this.Validators['Contains a number.'] = /\d/.test(newPass);
                this.Validators['Contains a letter.'] = /[a-zA-Z]/.test(newPass);
            },
            '$route.params.userId': function (id) {
                this.getUser();
            }
        },
        methods: {
            async checkEmailUsernameTaken () {
                if (!this.User.Email) return;
                try {
                    const result = await this.$root.axios.get('auth/usernameExists', { params: { UserName: this.User.Email } });
                    this.EmailUsernameTaken = result.data.Taken;
                } catch (e) {

                }
            },
            async checkUsernameTaken () {
                if (!this.User.UserName) return;
                try {
                    const result = await this.$root.axios.get('auth/usernameExists', { params: { UserName: this.User.UserName } });
                    this.UsernameTaken = result.data.Taken;
                } catch (e) {

                }
            },
            async getUser () {
                if (this.$route.params.userId !== 'new') {
                    try {
                        const response = await this.$root.axios.get(`user/${this.$route.params.userId}`);
                        this.User = response.data;
                        this.OrigUser = _.cloneDeep(response.data);
                    } catch (e) {
                        this.error = e.response.data;
                    }
                } else {
                    this.User = {
                        IsActive: true,
                    };
                }
            },
            async getRoles () {
                try {
                    const response = await this.$root.axios.get('role/');
                    this.Roles = response.data.map((r) => { return { value: r.Id, text: r.Name }; });
                    if (this.$route.params.userId === 'new') {
                        this.User.RoleId = this.Roles[0].value;
                    }
                } catch (e) {
                    this.error = e.response.data;
                }
            },
            async addUser () {
                if (this.PasswordUpdate.NewPassword !== this.PasswordUpdate.ConfirmPassword) return alert('Passwords don\'t match!');
                if (this.updating !== false) return;
                this.User.Password = this.PasswordUpdate.NewPassword;
                if (!this.EmailUsernameTaken) {
                    this.User.UserName = this.User.Email;
                }
                this.updating = true;
                this.success = null;
                this.error = null;
                try {
                    const result = await this.$root.axios.post('user/', this.User);
                    this.$router.push({ name: 'user', params: { userId: result.data.Id } })
                    this.success = 'User created.';
                    this.updating = false;
                } catch (e) {
                    this.updating = false;
                    this.error = e.response.data;
                    // if(e.response.status === 401) this.Password = '';
                }
            },
            async updateUser () {
                if (this.$route.params.userId === 'new') return this.addUser();
                if (this.updating !== false) return;
                this.updating = true;
                this.success = null;
                this.error = null;
                try {
                    const result = await this.$root.axios.put(`user/${this.$route.params.userId}`, this.User);
                    this.User = result.data;
                    this.OrigUser = _.cloneDeep(result.data);

                    this.success = 'User details updated.';
                    this.updating = false;
                } catch (e) {
                    this.updating = false;
                    this.error = e.response.data;
                    // if(e.response.status === 401) this.Password = '';
                }
            },
            async updatePassword () {
                if (this.PasswordUpdate.NewPassword !== this.PasswordUpdate.ConfirmPassword) return alert('Passwords don\'t match!');
                if (this.changingPassword !== false) return;
                this.changingPassword = true;
                this.success = null;
                this.error = null;
                try {
                    const result = await this.$root.axios.put(`user/${this.$route.params.userId}`, { Password: this.PasswordUpdate.NewPassword });
                    this.success = 'Your password has been changed!';
                    this.changingPassword = false;
                    this.changePassword = false;
                    this.PasswordUpdate = {};
                } catch (e) {
                    this.changingPassword = false;
                    this.error = e.response.data;
                    // if(e.response.status === 401) this.Password = '';
                }
            }
        }
    }
</script>

<style scoped>

</style>
