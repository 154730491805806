<template lang="pug">
    div
        .alert.alert-danger(v-if="error")
            span(v-if="error.errors")
                ul.mb-0
                    li(v-for="err in error.errors") {{err.message}}
            span(v-else)
                | {{error}}
        .alert.alert-success(v-if="success")
            | {{success}}
</template>

<script>
    export default {
        props: {
            error: { default: null, type: [String, Object, Boolean] },
            success: { default: null, type: [String, Boolean] },
        }
    }
</script>
