<template lang="pug">
    include ../mixins/plans.pug
    mixin yesBox
        .answer-container
            font-awesome-icon(:icon="['far','check']" fixed-width)
    mixin noBox
        .answer-container
            font-awesome-icon(:icon="['fal','times']" fixed-width)
    mixin pricingTable(dealer)
        div
            .row.plans-container.flex-column-reverse.flex-md-row
                - var filteredPlans = plans.filter(p => p.dealer == dealer || p.name == '')
                each plan, index in filteredPlans
                    .plan-container(
                    class=(index === 0 ? 'col-md col-xl-3 labels-only-container' : 'col-md'),
                    v-bind:class="{'active-plan': currentPlan === '"+plan.stripeName+"', 'new-plan': selectedPlan === '"+plan.stripeName+"'}",
                    v-if="('"+plan.stripeName+"' !== 'Free' || ('"+plan.stripeName+"' === 'Free' && !$store.state.auth.setup)) && ('"+plan.stripeName+"' !== 'Enterprise' || currentPlan === '')")
                        .price-plan-container
                            .plan-label.text-center #{plan.price > 0  ? plan.name : ''}
                            .price-container.text-center
                                if plan.price > 0
                                    .price-indicator $#{plan.price}
                                    .month-indicator /mo
                                else
                                    .free-indicator #{plan.name}
                        .select-plan-container.d-flex.align-items-center.justify-content-center
                            if plan.stripeName
                                .text-danger.text-uppercase(v-if="cancelled && billingDetails.StripeTrialEnds && '"+plan.stripeName+"' ==='Free'") Reverting to free plan after#[br]current billing period
                                .text-danger.text-uppercase(v-if="cancelled && !billingDetails.StripeTrialEnds && '"+plan.stripeName+"' ==='Free'") Reverting to free#[br]plan after trial
                                .current-plan(v-if="(!cancelled || !billingDetails.PaymentMethod) && currentPlan === '" + plan.stripeName + "'")
                                    | Current Plan
                                button.btn.btn-primary-green(v-if="cancelled && currentPlan === '" + plan.stripeName + "' && billingDetails.PaymentMethod", v-on:click="selectPlan('"+plan.stripeName+"')") RESUBSCRIBE
                                button.btn(class=(plan.price === 0 ? 'btn-light-gray' : 'btn-primary-green'), v-if="!pricingPage && (!$store.state.auth.setup || selectPlan) && $store.state.auth.authenticated && (!cancelled || '"+plan.stripeName+"' !=='Free') && currentPlan !== '" + plan.stripeName + "'", v-on:click="selectPlan('"+plan.stripeName+"')")
                                    if plan.price === 0
                                        | SELECT
                                    else
                                        span(v-if="trialEligible") TRY FREE
                                        span(v-else) SELECT
                                router-link.btn(class=(plan.price === 0 ? 'btn-light-gray' : 'btn-primary-green'), v-if="!$store.state.auth.authenticated || ($store.state.auth.setup && !selectPlan)", :to="{path: '/try-free', query: {plan: '"+plan.stripeName+"', dealer: "+plan.dealer+"}}")
                                    span(v-if="'Free' === '" + plan.stripeName + "'") SIGNUP
                                    span(v-if="'Free' !== '" + plan.stripeName + "'") TRY FREE
                                router-link.btn(class=(plan.price === 0 ? 'btn-light-gray' : 'btn-primary-green'), v-if="pricingPage && $store.state.auth.authenticated && currentPlan !== '" + plan.stripeName + "'", :to="{path: '/update-plan', query: {plan: '"+plan.stripeName+"'}}")
                                    span(v-if="'Free' === '" + plan.stripeName + "' || !trialEligible") SIGNUP
                                    span(v-if="'Free' !== '" + plan.stripeName + "' && trialEligible") TRY FREE
                        .plan-details
                            .row.plan-details-line.justify-content-end.align-items-center.font-weight-bold
                                .col
                                    | Lookups
                                if plan.stripeName
                                    .col
                                        div #{(plan.lookupsIncluded).toLocaleString('en', {})}#{plan.overageAllowed ? ' included' : ''}
                                        if plan.overageAllowed
                                            .overage-line Up to #{(plan.lookupsIncluded + plan.overageAllowed).toLocaleString('en', {})} w/ overage
                            .row.plan-details-line.justify-content-end.align-items-center.font-weight-bold(class=(!plan.overageAllowed ? 'grayed-out' : ''))
                                .col
                                    | Overage
                                if plan.stripeName
                                    .col
                                        if plan.overageAllowed
                                            | $#{plan.overagePrice}/lookup
                                        else
                                            +noBox
                            .row.plan-details-line.justify-content-end.align-items-center.font-weight-bold(class=(!plan.salesTaxReceipt ? 'grayed-out' : ''))
                                .col
                                    | CO Tax Receipt Printing
                                if plan.stripeName
                                    .col
                                        if plan.salesTaxReceipt
                                            +yesBox
                                        else
                                            +noBox
                            .row.plan-details-line.justify-content-end.align-items-center.font-weight-bold(class=(!plan.multiUser ? 'grayed-out' : ''))
                                .col
                                    | Multiple Users
                                if plan.stripeName
                                    .col
                                        if plan.multiUser
                                            +yesBox
                                        else
                                            +noBox
                            .row.plan-details-line.justify-content-end.align-items-center(class=(!plan.history ? 'grayed-out' : ''))
                                .col
                                    | History
                                if plan.stripeName
                                    .col
                                        if plan.history
                                            +yesBox
                                        else
                                            +noBox
                            .row.plan-details-line.justify-content-end.align-items-center(class=(!plan.autoUse ? 'grayed-out' : ''))
                                .col
                                    | Auto Use Tax
                                if plan.stripeName
                                    .col
                                        if plan.autoUse
                                            +yesBox
                                        else
                                            +noBox
                            .row.plan-details-line.justify-content-end.align-items-center(class=(!plan.buildingUse ? 'grayed-out' : ''))
                                .col
                                    | Building Use Tax
                                if plan.stripeName
                                    .col
                                        if plan.buildingUse
                                            +yesBox
                                        else
                                            +noBox
                            .row.plan-details-line.justify-content-end.align-items-center(class=(!plan.autocomplete ? 'grayed-out' : ''))
                                .col
                                    | Autocomplete
                                if plan.stripeName
                                    .col
                                        if plan.autocomplete
                                            +yesBox
                                        else
                                            +noBox
                            .row.plan-details-line.justify-content-end.align-items-center(class=(!plan.api ? 'grayed-out' : ''))
                                .col
                                    | API Access
                                if plan.stripeName
                                    .col
                                        if plan.api
                                            +yesBox
                                        else
                                            +noBox
            .need-more.text-center.mb-4
                h5 Need more than 2,500 lookups/mo? #[router-link(to="/contact-us") Get in touch] and we'll work something out!
    div
        .text-center
            h2.mb-2.page-title
                | Dealer Pricing
        +pricingTable(true)
        .text-center.mt-5
            h2.mb-2.page-title
                | Basic Pricing
        +pricingTable()
</template>

<script>
    export default {
        props: {
            switchPlans: {
                type: Boolean,
                default () {
                    return false;
                }
            },
            trialEligible: {
                type: Boolean,
                default () {
                    return false;
                }
            },
            currentPlan: {
                type: String,
                default () {
                    return null;
                }
            },
            cancelled: {
                type: Boolean,
                default () {
                    return false;
                }
            },
            pricingPage: {
                type: Boolean,
                default () {
                    return false;
                }
            },
            billingDetails: {
                type: [Object],
                default () {
                    return null;
                }
            },
        },
        data () {
            return {
                selectedPlan: 'Starter',
                checkbox: false,
            }
        },
        methods: {
            selectPlan (name) {
                this.$emit('planselect', name);
            },
        }
    }
</script>

<style lang="scss">
    @import '~bootstrap/scss/bootstrap-reboot.scss';
    @import '../scss/mixins';
    @import '../scss/Main';
    .plans-container {
        padding: 2rem 0;
        .plan-container {
            @include respond-to(md) {
                border-radius: 10px;
                overflow:hidden;
                &.active-plan {
                    border: 2px solid $primary-color;
                    padding-top: 1rem;
                }
                &.labels-only-container {
                    display: none;
                }
                margin: 2rem 0;
            }
            @include respond-to-min(md) {
                &:before {
                    display: block;
                    position: absolute;
                    content: '';
                    left: -2px;
                    top: -10px;
                    width: calc(100% + 4px);
                    height: calc(100% + 25px);
                    border: 2px solid transparent;
                    border-radius: 10px;
                    z-index: -1;
                }
                &.active-plan {
                    margin-left: 2px;
                    margin-right: 2px;
                    .price-plan-container {
                    }
                    &:before {
                        border-color: $primary-color;
                    }
                }
            }

            .select-plan-container {
                height: 6rem;
                padding: 1rem 0;
                text-align: center;
                .current-plan {
                    text-transform: uppercase;
                    font-size: 1.1rem;
                    font-weight: bold;
                    color: $primary-color;
                }
            }

            .plan-label {
                @include respond-to-min(md) {
                    height: 1.5rem;
                }
                font-size: 1.5rem;
                text-transform: uppercase;
                font-weight: 300;
                margin-bottom: .5rem;
            }
            .price-container {
                font-weight: bold;
                font-size: 2.5rem;
                height: 3rem;
                .free-indicator {
                    display: inline-block;
                }
                .price-indicator {
                    font-size: 3rem;
                    line-height: 3rem;
                    display: inline-block;
                }
                .month-indicator {
                    font-size: .8rem;
                    display: inline-block;
                }
                padding: 0 0 2rem 0;
            }
            .plan-details {
                .plan-details-line {
                    font-size: 1.1rem;
                    height: 4rem;
                    padding: 1rem 0;
                    .overage-line {
                        line-height: 1.1;
                        font-size: .9rem;
                    }
                    .col:last-child {
                        text-align: center;
                    }
                    @include respond-to(md) {
                        font-size: .9rem;
                        height: 2.5rem;
                        .col:last-child {
                            text-align: right;
                        }
                        .overage-line {
                            font-size: .7rem;
                        }
                    }
                    .answer-container {
                        color: $green;
                        font-size: 1.4rem;
                    }
                    &.grayed-out .answer-container {
                        color: $gray-500;
                    }
                    &:nth-child(even) {
                        background-color: $gray-100;
                    }
                }

            }
        }
        @include respond-to-min(md) {
            .plan-container:not(:first-child) .plan-details-line .col:first-child {
                display: none;
            }
            .plan-container:first-child .plan-details-line .col:first-child {
                text-align: left;
            }
        }
    }

</style>
