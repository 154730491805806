<template lang="pug">
    -
        function camelize(str) {
            return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
                if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
                return index == 0 ? match.toLowerCase() : match.toUpperCase();
            });
        }
    mixin input(title, model, attr)
        .form-group&attributes(attributes)
            label(for=camelize(title)) #{title}
            input.form-control(type="text", id=camelize(title), name=camelize(title), v-model=model)&attributes(attr)
    .container.mb-5
        h1.page-title
            | Contact Us
        .row.justify-content-center
            .col.mb-5
                div
                    strong Dealr, Inc.
                    br
                    a(href="mailto:support@dealr.tax",target="_blank") support@dealr.tax
            .col-lg-7
                p Need to get in touch? Please use the button below to send us an email.
                p If you're an existing dealr.tax client and you've received a rejection or notice from an official agency, like the state or a county, please attach it to your email. If you have not received a rejection and believe the tax rate to be incorrect, we'll need you to first receive a rejection before we can perform further investigation into the matter.
                a.btn.btn-secondary(href="mailto:support@dealr.tax",target="_blank") Send an Email

                //form(v-on:submit.prevent="sendContactForm", v-if="!success")
                //    .row
                //        .col-md
                //            +input('Name', 'Contact.Name', {placeholder: "Johnny Appleseed"})
                //        .col-md
                //            +input('Company', 'Contact.Company', {placeholder: "Acme, Inc."})
                //    +input('Email', 'Contact.Email', {placeholder: "johnny@acme.com"})
                //    .form-group
                //        label(for="message") Message
                //        textarea.form-control(id="message", name="message", v-model="Contact.Message", placeholder="Enter your message here...")
                //    button.btn.btn-secondary(v-button-loading="sending", type="submit") Send Message
                //response-container.mt-3(:success="success", :error="error")
</template>

<script>
    export default {
        metaInfo: {
            title: 'Contact',
            meta: [
                {
                    name: 'description',
                    content: 'dealr.tax is a tax rate locator system designed for small business, eCommerce, auto dealers, developers, the construction industry and more!'
                }
            ],
        },
        data () {
            return {
                Contact: {},
                success: null,
                error: null,
                sending: false,
            }
        },
        methods: {
            async sendContactForm () {
                if (this.sending !== false) return;
                this.sending = true;
                this.success = null;
                this.error = null;
                try {
                    const result = await this.$root.axios.post('contact', this.Contact);
                    this.success = result.data;
                    this.sending = false;
                } catch (e) {
                    this.sending = false;
                    this.error = e.response.data;
                }
            },
        }
    }
</script>

<style lang="scss">

</style>
