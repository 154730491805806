<template lang="pug">
    div
        .mb-5
            .container
                .row.page-title-container
                    .col-md
                        h1.page-title-no-margin
                            | Users
                    .col-auto.align-self-center
                        button.btn.btn-primary-green(@click="addUser") Add User
                b-form-checkbox(v-model="DisabledUsers", v-on:change="disabledUsersChange") Disabled Users
                .form-group.mt-4
                    input#searchQuery.form-control(type="text", placeholder="Search users...", v-model="SearchQuery", v-on:keyup="onSearchChange")
                vue-good-table(
                :columns="columns",
                :rows="rows",
                :search-options="{enabled: true, externalQuery: SearchQuery,}",
                :sort-options="SortOptions",
                :pagination-options="PaginationOptions",
                @on-row-click="openUser",
                :totalRows="totalRecords",
                :isLoading="isLoading",
                :showError="error",
                :sortable=false
                )
                    div(slot="emptystate")
                        | No lookups could be found.
                    div(slot="errorstate")
                        .alert.alert-danger {{error}}
</template>

<script>
    import VueGoodTable from '../../components/VueGoodTable/Table';
    import moment from 'moment';
    import axios from 'axios';
    import _ from 'lodash';
    import { BFormCheckbox as bFormCheckbox } from 'bootstrap-vue'

    import { mapGetters, mapState } from 'vuex'

    // import the styles
    export default {
        metaInfo: {
            title: 'Users'
        },
        created () {
            this.$root.requireAuth();
            this.loadItems();
        },
        computed: {
            ...mapState({
                serverParams: state => state.usersParams,
            })
        },
        data () {
            const usersParams = _.cloneDeep(this.$store.state.usersParams)

            return {
                error: null,
                isLoading: false,
                Reference: null,
                SearchQuery: usersParams.Query,
                DisabledUsers: usersParams.disabledUsers,
                PaginationOptions: {
                    enabled: true,
                    mode: 'pages',
                    perPageDropdown: [10, 25, 50],
                    dropdownAllowAll: false,
                    pageText: '',
                },
                SearchOptions: {
                    enabled: true,
                    placeholder: 'Search users...',
                    externalQuery: this.SearchQuery,
                },
                SortOptions: {
                    enabled: true,
                },
                columns: [
                    {
                        label: 'Name',
                        field: 'Name',
                        sortable: false,
                        html: 1,
                        formatFn (val, rowData) {
                            return `<div class="reference-column-value">${rowData.IsMaster ? '<span class="badge badge-primary mr-2">Master</span>' : ''}${val}</div><small class="reference-column-address">${rowData.IsApiUser ? '<span class="badge badge-primary mr-2">API User</span>' : ''}${rowData.UserName ? rowData.UserName : ''}</small>`
                        }
                    },
                    {
                        label: 'Role',
                        field: 'Role.Name',
                        type: 'text',
                        sortable: false,
                    },
                    {
                        label: 'Quota',
                        field: 'Quota',
                        type: 'number',
                        html: 1,
                        formatFn: (v) => {
                            return v ? this.$root.formatPrice(v, 0, '.', ',', '') : '<i class="far fa-infinity"/>';
                        },
                        sortable: false,
                    },
                ],
                rows: [

                ],
                totalRecords: 0,
                lastRequest: null,
            }
        },
        components: {
            VueGoodTable,
            bFormCheckbox,
        },
        methods: {
            openUser (params) {
                this.$router.push({ name: 'user', params: { userId: params.row.Id } })
            },
            updateParams (newProps) {
                this.$store.dispatch('usersParams/updateParams', newProps);
                // this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
            disabledUsersChange (checked) {
                this.$store.dispatch('usersParams/updateParams', { disabledUsers: checked });
                setTimeout(this.loadItems);
            },
            onSearchChange () {
                this.$store.dispatch('usersParams/updateParams', { Query: this.SearchQuery });
            },

            /*
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            }, */

            async loadItems () {
                try {
                    this.isLoading = true;
                    this.error = null;
                    const response = await this.$root.axios.get('user/', { params: this.serverParams });
                    this.rows = response.data;
                    this.isLoading = false;
                } catch (e) {
                    if (e.response) {
                        this.error = e.response.data;
                    } else {
                        this.error = 'Server request failed.';
                    }
                    this.isLoading = false;
                }
            },
            addUser () {
                this.$router.push({ name: 'user', params: { userId: 'new' } })
            },
        }
    }
</script>

<style lang="scss">
    @import '../../scss/VueGoodTable';
    .reference-column-value {
        line-height: 1;
        margin-bottom: -.1em;
        margin-top: .4em;
    }
    .reference-column-address{
        line-height: 1;
    }
</style>
