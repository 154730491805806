<template lang="pug">
    .green-bg-page
        .mb-5
            .container
                .row.justify-content-center.mt-5
                    .col-md-4.content-container
                        .row.justify-content-center
                            .col-md
                                h4.mb-3.font-weight-bold RESET PASSWORD
                                response-container(:error="error", :success="successMessage")
                                form(v-on:submit.prevent="changePassword", v-if="UserName")
                                    p Changing password for #[strong {{UserName}}]
                                    input#password.form-control.mb-3(type="password", placeholder="Password", v-model="Password", v-autofocus-on-load)
                                    ul
                                        li(v-for="(valid, string) in Validators", v-bind:class="{'text-danger': !valid, 'text-success': valid}")
                                            | {{string}}
                                    input#confirmPassword.form-control.mb-3(type="password", placeholder="Confirm Password", v-model="ConfirmPassword", v-autofocus-on-load)
                                    .text-right
                                        button.font-weight-bold.btn.btn-primary-green(type="submit", v-button-loading="changing")
                                            | Change
                                            font-awesome-icon.ml-1(:icon="['fas','arrow-alt-right']", fixed-width)
</template>

<script>
    export default {
        metaInfo: {
            title: 'Change Password'
        },
        data () {
            return {
                UserName: '',
                changing: false,
                error: null,
                successMessage: null,
                Password: null,
                ConfirmPassword: null,
                Validators: {
                    'Contains a number.': false,
                    'Contains a letter.': false,
                    '8 characters long.': false,
                },
            }
        },
        created () {
            this.getStatus();
            if (this.$store.state.auth.authenticated) {
                this.$router.push({ name: 'home' });
            }
        },
        watch: {
            Password (newPass, oldPass) {
                this.Validators['8 characters long.'] = newPass.length >= 8;
                this.Validators['Contains a number.'] = /\d/.test(newPass);
                this.Validators['Contains a letter.'] = /[a-zA-Z]/.test(newPass);
            },
        },
        methods: {
            async getStatus () {
                try {
                    const result = await this.$root.axios.post('auth/reset/status', { ResetToken: this.$route.params.resetToken });
                    this.UserName = result.data.UserName;
                } catch (e) {
                    this.error = e.response.data;
                }
            },
            async changePassword () {
                if (this.Password !== this.ConfirmPassword) return alert('Passwords don\'t match!');
                if (this.changing !== false) return;
                this.changing = true;
                this.successMessage = null;
                this.error = null;
                try {
                    const result = await this.$root.axios.post('auth/reset/changePassword', { ResetToken: this.$route.params.resetToken, Password: this.Password });
                    this.successMessage = 'Your password has been changed!';
                    setTimeout(() => {
                        this.$router.push({ name: 'home' });
                    }, 5000);
                    this.changing = false;
                } catch (e) {
                    this.changing = false;
                    this.error = e.response.data;
                    // if(e.response.status === 401) this.Password = '';
                }
            },
        }
    }
</script>

<style lang="scss">

</style>
