<template lang="pug">
    div
        #home-hero-container.navbar-padding
            // .container
                .row.justify-content-center.align-items-center
                    .col-lg-8
                        router-link.d-block.website-announcement.mt-3.danger.text-center(to="contact")
                            | Welcome to the new dealr.tax! Click here to report bugs/problems.
                            font-awesome-icon.ml-1(:icon="['far','chevron-right']", fixed-width)
            .container
                #home-hero.row.justify-content-center.align-items-center
                    .col-lg-8.col-lg-push-2
                        #home-hero-header
                            h2#home-hero-header-fixed-text.text-uppercase.font-weight-light.mb-0 TAX RATES FOR
                            h1#scrolling-text.font-weight-bold.mt-0.mb-5
                                .current-scrolling-text Business
                                .next-scrolling-text Dealers
                            home-searchbar
                            .text-center
                                router-link.font-weight-bold.btn.btn-light.mt-5(dealr-track-click='ButtonInteractions,TryForFree', to='/try-free', v-if="!$store.state.auth.authenticated")
                                    | Try For Free
                                    font-awesome-icon.ml-1(:icon="['fas','arrow-alt-right']", fixed-width)

        -
            const features = [
            {
            title: 'Easy Interface',
            text: 'Get the data you need quickly using the clean and easy-to-use dealr.tax interface.',
            icon: 'fas universal-access'
            },
            {
            title: 'Autocomplete',
            text: 'Start typing your address and automagically see a list of likely matches.',
            icon: 'fas magic'
            },
            {
            title: 'For Dealers',
            text: 'Access auto use tax by address for your financed deals so your titles don\'t get kicked back.',
            icon: 'fas car'
            },
            {
            title: 'For Construction',
            text: 'Determine building use tax for building materials and material deliveries',
            icon: 'fas building'
            },
            {
            title: 'For Developers',
            text: 'Access our <router-link to="/api-docs">documented API</router-link> to easily integrate the dealr.tax rate lookup into your systems.',
            icon: 'fas code'
            }
            ]
        .container.py-5
            .row.justify-content-around
                each feature in features
                    .col-lg-5.col-md-8.py-5
                        .row.align-items-center
                            .col-auto
                                font-awesome-icon.dealr-text-green(:icon=feature.icon.split(' '), fixed-width, size="3x")
                            .col
                                h4.dealr-text-green.text-uppercase.font-weight-bold #{feature.title}
                                p.mb-0 !{feature.text}
</template>

<script>
    import HomeSearchbar from '@/components/HomeSearchbar.vue'
    import $ from 'jquery'

    export default {
        metaInfo: {
            title: 'Sales Tax Rate Locator'
        },
        components: {
            HomeSearchbar,
        },
        data () {
            return {
                flipInterval: null,
                words: [
                    'Small Business',
                    'Car Dealers',
                    'Builders',
                    'Developers',
                    'RV Dealers',
                    'eCommerce',
                    'Store Owners',
                    'Powersports Dealers',
                    'Shippers',
                    'Building Material Sellers',
                ],
                nextWord: 'Dealers',

            }
        },
        mounted () {
            const cont = $('#scrolling-text');
            let text1;
            let text2;
            this.flipInterval = setInterval(() => {
                text1 = cont.find('.current-scrolling-text');
                text2 = cont.find('.next-scrolling-text');
                text1.addClass('hide');
                text2.addClass('show');
                setTimeout(() => {
                    let index = this.words.indexOf(this.nextWord) + 1;
                    if (index > this.words.length - 1) index = 0;
                    this.nextWord = this.words[index];
                    text2.addClass('current-scrolling-text').removeClass('next-scrolling-text').removeClass('show');
                    text1.addClass('next-scrolling-text').removeClass('current-scrolling-text').removeClass('hide').text(this.nextWord);
                }, 300)
            }, 3000);
        },
        beforeDestroy () {
            clearTimeout(this.flipInterval);
        },
        methods: {
            change () {
                if (cont.find('.current-scrolling-text').hasClass('hide')) {
                    cont.find('.current-scrolling-text').removeClass('hide');
                    cont.find('.next-scrolling-text').removeClass('show');
                } else {
                    cont.find('.current-scrolling-text').addClass('hide');
                    cont.find('.next-scrolling-text').addClass('show');
                }
            }
        },
    }
</script>

<style lang="scss">
    @import '../scss/Main.scss';
    $text-flip-animation-length: .3s;
    $text-flip-y-offset: $h1-font-size+.25rem;
    $text-flip-angle: 45deg;
    #home-hero-container {
        background-color: $primary-color;
        color: #ffffff;
        background: linear-gradient(226deg, #228da5, #23a522);
        background-size: 800% 800%;

        animation: AnimationName 20s ease infinite;

        @-webkit-keyframes AnimationName {
            0%{background-position:0% 50%}
            50%{background-position:100% 50%}
            100%{background-position:0% 50%}
        }
        @-moz-keyframes AnimationName {
            0%{background-position:0% 50%}
            50%{background-position:100% 50%}
            100%{background-position:0% 50%}
        }
        @keyframes AnimationName {
            0%{background-position:0% 50%}
            50%{background-position:100% 50%}
            100%{background-position:0% 50%}
        }
    }
    #home-hero {
        height: 0;
        min-height: 400px;
    }
    #scrolling-text {
        height: $h1-font-size * $headings-line-height;
        margin-bottom: $headings-margin-bottom;
        padding-top: .5rem;
        padding-bottom: .5rem;
        div {
            white-space: nowrap;
            position: absolute;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .next-scrolling-text {
            visibility: hidden;
            opacity: 0;
            transform: rotateX(-$text-flip-angle) translateY($text-flip-y-offset);
            transition: transform $text-flip-animation-length ease, opacity $text-flip-animation-length ease, visibility $text-flip-animation-length ease;
            &.show {
                visibility: visible;
                opacity: 1;
                transform: none;
            }
        }
        .current-scrolling-text {
            visibility: visible;
            opacity: 1;
            transform: none;
            transition: transform $text-flip-animation-length ease, opacity $text-flip-animation-length ease, visibility $text-flip-animation-length ease;
            &.hide {
                visibility: hidden;
                opacity: 0;
                transform: rotateX($text-flip-angle) translateY(-$text-flip-y-offset);
            }
        }
     }
</style>
