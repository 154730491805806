<template lang="pug">
    div
        .form-group
            label(for=cardName) Name on Card
            input.form-control(type="text", id="cardName", name="cardName", v-model="nameOnCard", data-lpignore="true")
        .form-group
            label(for=cardElement) Credit or Debit Card
            #cardElement.form-control(@change="stripeChange")
        address-input(:address="address", address-label="Billing Address")
        #cardErrors.alert.alert-danger(v-if="error") {{error}}
</template>

<script>
    import AddressInput from '@/components/AddressInput';
    export default {
        components: {
            AddressInput,
        },
        mounted () {
            if (window.stripe) {
                this.stripeInit();
            } else {
                const stripeWatch = this.$watch('stripeConnected', (newVal) => {
                    if (newVal === true) {
                        stripeWatch();
                        this.stripeInit();
                    }
                })
            }
        },
        computed: {
            stripeConnected () {
                return this.$store.state.stripe.stripe;
            },
        },
        /* props: {
            address: {
                type: Object,
                default () {
                    return {};
                }
            },
            nameOnCard: {
                type: String,
                default () {
                    return null;
                }
            }
        }, */
        data () {
            return {
                address: {},
                nameOnCard: null,
                stripe: null,
                elements: null,
                card: null,
                error: null,
                cardOptions: {
                    style: {
                        base: {
                            fontSize: '16px'
                        },
                    },
                    hidePostalCode: true,
                },
            };
        },
        methods: {
            stripeInit () {
                this.stripe = window.stripe;
                this.elements = this.stripe.elements();
                this.card = this.elements.create('card', this.cardOptions);
                this.card.mount('#cardElement');
            },
            stripeChange (event) {
                if (event.error) {
                    this.error = event.error.message;
                } else {
                    this.error = null;
                }
            },
            async getStripeToken () {
                this.error = null;
                if (!this.nameOnCard) {
                    this.error = 'Fill out the name on the card.';
                    return false;
                }
                if (!this.address ||
                    (!('Line1' in this.address) ||
                        !('City' in this.address) ||
                        !('State' in this.address) ||
                        !('Zip' in this.address)
                    )) {
                    this.error = 'Incomplete address.';
                    return false;
                }
                const { token, error } = await this.stripe.createToken(this.card, {
                    name: this.nameOnCard,
                    address_line1: this.address.Line1,
                    address_line2: this.address.Line2,
                    address_city: this.address.City,
                    address_state: this.address.State,
                    address_zip: this.address.Zip,
                    address_country: 'US',
                });
                if (error) {
                    this.error = error.message;
                    return false;
                }
                if (this.error) return false;

                return token.id;
            }
        },
    }
</script>

<style lang="scss">
    @import '~bootstrap/scss/bootstrap-reboot';
    @import '~bootstrap/scss/forms';
    .StripeElement {
        padding: $input-padding-y + .125rem $input-padding-x + .125rem;
        height: auto !important;
    }

    .StripeElement--focus {
        color: $input-focus-color;
        background-color: $input-focus-bg;
        border-color: $input-focus-border-color;
        outline: 0;
        // Avoid using mixin so we can pass custom focus shadow properly
        @if $enable-shadows {
            box-shadow: $input-box-shadow, $input-focus-box-shadow;
        } @else {
            box-shadow: $input-focus-box-shadow;
        }
    }

    .StripeElement--invalid {
        border-color: #fa755a;
    }

    .StripeElement--webkit-autofill {
        background-color: #fefde5 !important;
    }
</style>
