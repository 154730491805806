<template lang="pug">
    include ../../mixins/mixins.pug
    div
        .mb-5
            .container
                h1.page-title
                    | Finish Signup
                response-container(:success="success", :error="error", v-if="!newPlan")
                pricing-view(@planselect="selectPlan", v-if="!newPlan", :trial-eligible="trialEligible")
                div(v-if="newPlan")
                    .row.justify-content-center
                        .col-lg-6.col-md-4
                            .d-flex.justify-content-center
                                +planDisplay('Plan', true)(v-if="newPlan === plan.stripeName").new-plan
                    .row.justify-content-center
                        .col-lg-6
                            form(v-on:submit.prevent="finishSignup").mt-4
                                .form-group
                                    label(for=companyName) Company
                                    input.form-control(type="text", id="companyName", name="companyName", v-model="SignupInfo.CompanyName")
                                .row
                                    .col-md
                                        .form-group
                                            label(for=nameFirst) First Name
                                            input.form-control(type="text", id="nameFirst", name="nameFirst", v-model="SignupInfo.NameFirst")
                                    .col-md
                                        .form-group
                                            label(for=nameLast) Last Name
                                            input.form-control(type="text", id="nameLast", name="nameLast", v-model="SignupInfo.NameLast")
                                .form-group
                                    label(for="industrySelect") Industry
                                    b-form-select#roleSelect(v-model="SignupInfo.Industry")
                                        option
                                        option Auto/Vehicle Dealer
                                        option Finance
                                        option Construction
                                        option eCommerce
                                        option Retail
                                        option Other
                                .form-group(v-if="SignupInfo.Industry === 'Other'")
                                    label(for=otherIndustry) Other Industry Description
                                    input.form-control(type="text", id="otherIndustry", name="otherIndustry", v-model="SignupInfo.OtherIndustry")
                                .form-group
                                    label(for="industrySelect") Company Size
                                    b-form-select#roleSelect(v-model="SignupInfo.CompanySize")
                                        option
                                        option Just me
                                        option 2-10
                                        option 10-50
                                        option 50+

                                div(v-if="newPlan !== 'Free'").mt-4
                                    .text-center.mb-4(v-if="trialEligible === true")
                                        button.btn.btn-light-gray(v-if="!enterBillingDetails", v-on:click="enterBillingDetails = true", type="button") Enter Payment Info
                                        button.btn.btn-light-gray(v-if="enterBillingDetails", v-on:click="enterBillingDetails = false", type="button") Continue w/o Payment Info
                                    .text-center.alert.alert-info.mb-4(v-else-if="trialEligible === false") It looks like you've signed up for too many trials - we have to cut you off. To sign up for an account please provide your payment info!
                                    collect-stripe-payment-details.mb-4(ref="stripeController", v-if="trialEligible === false || enterBillingDetails")
                                response-container(:success="success", :error="error")
                                p(v-html="buttonMessage")
                                .text-center
                                    button.btn.btn-primary-green(type="submit", v-button-loading="finishing")
                                        | {{buttonLabel}}

</template>

<script>
    import ResponseContainer from '@/components/ResponseContainer';
    import PricingView from '@/components/PricingView';
    import CollectStripePaymentDetails from '@/components/CollectStripePaymentDetails';
    import { BFormSelect as bFormSelect } from 'bootstrap-vue'

    export default {
        metaInfo: {
            title: 'Finish Signup'
        },
        components: {
            ResponseContainer,
            PricingView,
            CollectStripePaymentDetails,
            bFormSelect,
        },
        data () {
            return {
                success: null,
                error: null,
                newPlan: null,
                trialEligible: null,
                finishing: false,
                enterBillingDetails: false,
                SignupInfo: {},
            };
        },
        created () {
            const plan = this.$cookies.get('DealrPlanSelection');
            if (plan && plan !== 'Free' && this.plans.filter(p => p.name === plan).length > 0) {
                this.newPlan = plan;
            }
            this.getTrialEligibility();
        },
        computed: {
            plans () {
                return this.$store.state.plans.plans;
            },
            newPlanPlan () {
                for (let i = 0; i < this.plans.length; i++) {
                    if (this.plans[i].stripeName === this.newPlan) {
                        return this.plans[i];
                    }
                }
                return null;
            },
            buttonLabel () {
                if (!this.newPlanPlan) return null;
                return this.newPlanPlan.stripeName === 'Free' ? 'Finish Setup' : `${this.trialEligible ? 'Start Trial' : 'Subscribe'}`;
            },
            buttonMessage () {
                if (!this.newPlanPlan) return null;
                if (this.newPlanPlan.stripeName !== 'Free') {
                    return `By clicking '${this.buttonLabel}' you will be subscribed to dealr.tax and ${this.trialEligible ? `start a 14-day free trial. <br> ${this.enterBillingDetails ? 'Your card will be charged monthly starting at the end of the trial.' : 'If you don\'t enter your payment details by the end of the trial your account will be reverted to the Free plan.'}` : 'be charged the subscription fee.'}`;
                }
                return '';
            },
        },
        methods: {
            selectPlan (planName) {
                this.newPlan = planName;
            },
            async getTrialEligibility () {
                try {
                    const response = await this.$root.axios.get('auth/trialEligible');
                    this.trialEligible = response.data.Eligible;
                } catch (e) {
                    this.trialEligible = false;
                }
            },
            async finishSignup () {
                this.finishing = true;
                this.error = false;
                let token;
                if (this.newPlanPlan.name !== 'Free' && (!this.trialEligible || this.enterBillingDetails)) {
                    if (!this.$refs.stripeController) {
                        this.error = 'We weren\'t able to save your card details.';
                        this.finishing = false;
                        return false;
                    }
                    token = await this.$refs.stripeController.getStripeToken();
                    if (token === false) {
                        this.finishing = false;
                        return false;
                    }
                }
                try {
                    const request = this.SignupInfo;
                    request.Plan = this.newPlan;
                    if (token) {
                        request.StripeToken = token;
                    }
                    const result = await this.$root.axios.post('auth/signup/companyInfo', request);
                    this.$router.push({ name: 'home' });
                    this.finishing = false;
                } catch (e) {
                    this.finishing = false;
                    if (e.response) {
                        this.error = e.response.data;
                    } else {
                        this.error = 'There was an internal server error. Please try again.';
                    }
                    // if(e.response.status === 401) this.Password = '';
                }
            },
        }
    }
</script>

<style lang="scss">

</style>
