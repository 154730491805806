<template lang="pug">
    .green-bg-page
        .mb-5
            .container
                .row.justify-content-center.mt-5
                    .col-md-8.content-container
                        .alert.alert-danger.mb-4(v-if="error") {{error}}
                        .alert.alert-success.mb-4(v-if="successMessage") {{successMessage}}
                        .row.justify-content-center
                            .col-md
                                h4.mb-4.font-weight-bold FORGOT PASSWORD
                                form(v-on:submit.prevent="resetPassword")
                                    input#username.form-control.mb-3(type="text", placeholder="Username or Email", v-model="UserName", v-autofocus-on-load)
                                    .text-right
                                        button.font-weight-bold.btn.btn-primary-green(type="submit", v-button-loading="resetting")
                                            | Reset
                                            font-awesome-icon.ml-1(:icon="['fas','arrow-alt-right']", fixed-width)
                            .col-md
                                h4.mb-4.font-weight-bold FORGOT USERNAME
                                form#sendUsername(v-on:submit.prevent="sendUsername")
                                    input.form-control.mb-3(type="text", placeholder="Email", v-model="Email", name="Email")
                                    .text-right
                                        vue-recaptcha(v-bind:sitekey="RecaptchaSiteKey", size="invisible", ref="invisibleRecaptcha", @verify="confirmSendUsername")
                                        button.font-weight-bold.btn.btn-primary-green(type="submit", v-button-loading="sendingUsername")
                                            | Send Username
                                            font-awesome-icon.ml-1(:icon="['fas','paper-plane']", fixed-width)
</template>

<script>
    import $ from 'jquery'
    import VueRecaptcha from 'vue-recaptcha';
    export default {
        metaInfo: {
            title: 'Forgot Password'
        },
        components: { VueRecaptcha },
        data () {
            return {
                RecaptchaSiteKey: process.env.VUE_APP_RECAPTCHA_KEY,
                UserName: '',
                Email: '',
                resetting: false,
                sendingUsername: false,
                error: null,
                successMessage: null,
            }
        },
        created () {
            if (this.$store.state.auth.authenticated) {
                this.$router.push({ name: 'home' });
            }
        },
        methods: {
            async sendUsername (ev) {
                const data = {};
                $('#sendUsername').serializeArray().forEach((d) => {
                    data[d.name] = d.value;
                });
                if (!data['g-recaptcha-response']) {
                    this.sendingUsername = 1;
                    setTimeout(() => {
                        if (this.sendingUsername === 1) {
                            this.sendingUsername = false;
                        }
                    }, 1000);
                    this.$refs.invisibleRecaptcha.execute()
                } else {
                    if (this.sendingUsername !== false) return;
                    this.sendingUsername = 2;
                    this.successMessage = null;
                    this.error = null;
                    try {
                        const result = await this.$root.axios.post('auth/reset/remindUsername', data);
                        this.successMessage = result.data;
                        this.sendingUsername = false;
                    } catch (e) {
                        this.sendingUsername = false;
                        this.error = e.response.data;
                        // if(e.response.status === 401) this.Password = '';
                    }
                    this.$refs.invisibleRecaptcha.reset();
                }
            },
            confirmSendUsername (response) {
                this.sendingUsername = false;
                setTimeout(this.sendUsername)
            },
            async resetPassword () {
                if (this.resetting) return;
                this.resetting = true;
                this.error = null;
                this.successMessage = null;
                try {
                    const result = await this.$root.axios.post('auth/reset', { UserName: this.UserName });
                    this.successMessage = result.data;
                    this.resetting = false;
                } catch (e) {
                    this.error = e.response.data;
                    // if(e.response.status === 401) this.Password = '';
                    this.resetting = false;
                }
            }
        }
    }
</script>

<style lang="scss">

</style>
