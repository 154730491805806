<template lang="pug">
    .container.my-5
        include:markdown-it TermsOfUse.md
</template>

<script>
    export default {
        metaInfo: {
            title: 'Terms of Use'
        },
    }
</script>
