<template lang="pug">
    footer#footer-main.dealr-text-lightblue.d-print-none
        .container
            .row.align-items-center.justify-content-between
                .col-md-6.col-lg-8.sitemap.text-center.text-md-left
                    .row.no-gutter
                        .col-sm-6.col-lg-3
                            ul.sitemap-list.footer-features
                                li
                                    router-link(to='/lookup')
                                        | Rate Lookup
                                li(v-if="Auth.authenticated && Auth.planDetails && Auth.planDetails.History")
                                    router-link(to='/lookups')
                                        | Lookup History
                                li(v-if="!Auth.authenticated || Auth.plan === 'Free'")
                                    router-link(to='/pricing')
                                        | Pricing
                                li(v-if="!Auth.authenticated")
                                    router-link(to='/login')
                                        | Login
                        .col-sm-6.col-lg-4
                            ul.sitemap-list
                                li
                                    router-link(to='/report-problem', google-track="reportProblem")
                                        | Report Problem
                                li
                                    router-link(to='/contact', google-track="contact")
                                        | Contact
                                li
                                    router-link(to='/api-docs', google-track="apiDocs")
                                        | API Documentation

                        .col-sm-6.col-lg-5
                            ul.sitemap-list
                                li
                                    router-link(to='/how-colorado-auto-taxes-work', google-track="howColoradoAutoTaxesWork")
                                        | How Colorado Auto Taxes Work
                                li
                                    router-link(to='/new-dr0024-sales-tax-receipt', google-track="newDR0024SalesTaxReceipt")
                                        | New DR0024 Sales Tax Receipt

                .col-md-6.col-lg-4.text-md-right.text-center
                    img(src='../assets/logo.svg', height='50px', alt='dealr.tax Logo')
                    .mt-5
                        router-link(to="/terms-of-use", google-track="termsOfUse") Terms of Use
                        |
                        | |
                        |
                        router-link(to="/privacy-policy", google-track="privacyPolicy") Privacy Policy
                        br
                        | Copyright © {{new Date()|moment("YYYY")}} Dealr, Inc.
</template>

<script>
    import VueMoment from 'vue-moment'
    import Vue from 'vue'
    Vue.use(VueMoment)

    export default {
        computed: {
            Auth () {
                return this.$store.getters['auth/auth'];
            },
        }
    }
</script>

<style lang="scss" src="../scss/Footer.scss"></style>
