<template lang="pug">
    mixin displayTaxes(label, index, totalIndex, amountIndex, totalAmountIndex)
        div&attributes(attributes)
            h3.sales-tax-container-label !{label}
            ul
                li(v-for="rate in lookupCalculated.Jurisdictions", v-if="rate."+index+" >= 0")
                    .row.align-items-center
                        .col
                            .jurisdiction-label {{rate.Type}}
                            .jurisdiction-name(v-tooltip.top-center="rate.Name", v-show-tooltip-on-text-overflow="rate.Name") {{rate.Name}}
                            .jurisdiction-placeholder
                            .jurisdiction-fips(v-if="rate.FipsCode") FIPS {{ rate.FipsCode }}
                        .col-auto.text-right.jurisdiction-rate(v-bind:class="{'jurisdiction-rate-amount':rate."+amountIndex+"}")
                            div(v-bind:class="{'jurisdiction-rate-small':rate."+amountIndex+"}")
                                | {{rate.#{index}}}%
                            .jurisdiction-amount(v-if="rate."+amountIndex)
                                | {{rate.#{amountIndex}}}
            if totalIndex
                .row.align-items-end
                    .col
                        .total-label Total
                    .col.text-right(v-bind:class="{'total-rate-amount':lookupCalculated."+totalAmountIndex+"}")
                        .total-rate {{lookup.#{totalIndex}}}%
                        if totalAmountIndex
                            .total-amount {{lookupCalculated.#{totalAmountIndex}}}
    div
        h1.text-center.d-none.d-print-block(v-if="!$store.state.auth.authenticated") Sign up for a free account in order to print tax rates!
        div(v-bind:class="{ 'd-print-none': !$store.state.auth.authenticated }")
            #lookup-view.mb-5
                .container
                    .row.page-title-container
                        .col-md
                            h1.page-title-no-margin
                                | Rate Lookup
                        .col-md.align-self-center
                            usage-indicator.my-3.my-md-0
                    .loader(v-if="loading")
                        div.text-center.py-5
                            h4.mb-4 Loading...
                            img(src="../assets/green-loader-medium.svg", height="75px")
                    .error-container(v-if="error")
                        .row.justify-content-center
                            .col-md-8.text-center
                                .alert-danger.p-4 {{error}}
                                router-link.btn.btn-primary-green.font-weight-bold.mt-4(to="/lookup")
                                    font-awesome-icon.mr-2(:icon="['far','plus-circle']")
                                    | NEW ADDRESS
                    .result-container(v-if="$route.name === 'max-lookups-used'")
                        .row
                            .col
                                .address-container
                                    .address-label Input Address
                                    .address-line {{ $route.query.Address }}
                        .row.justify-content-center.mt-5
                            .col-md-8.text-center
                                .alert.alert-danger.p-4(v-if="!$store.state.auth.authenticated")
                                    p.mb-0 All your tax lookups have been used.
                                    h5.dealr-text-green To get 15 lookups/mo sign up for an account!
                                    router-link.font-weight-bold.btn.btn-primary-green.mt-4(dealr-track-click='ButtonInteractions,TryForFree', to='/try-free')
                                        | Try For Free
                                        font-awesome-icon.ml-1(:icon="['fas','arrow-alt-right']", fixed-width)
                                .alert.alert-danger.p-4(v-if="$store.state.auth.authenticated")
                                    p.mb-0 All your tax lookups have been used.
                                    p.mb-0.font-weight-bold.dealr-text-green To get more lookups increase your user quota or upgrade your account!
                                    router-link.font-weight-bold.btn.btn-primary-green.mt-4(dealr-track-click='ButtonInteractions,UpgradeAccount', to='/billing')
                                        | Upgrade
                                        font-awesome-icon.ml-1(:icon="['fas','arrow-alt-up']", fixed-width)
                    .result-container(v-if="lookup")
                        .mb-5
                            .row
                                .col-md
                                    .address-container
                                        .address-label Matched Address
                                        .address-line(v-if="lookup.MatchedAddress.Addr.length < 5", v-for="line in lookup.MatchedAddress.Addr") {{ line }}
                                        .address-line(v-else) {{ lookup.MatchedAddress.Addr }}

                                    .address-container(v-if="lookup.ColoradoJurisdictionCode")
                                        .address-label Jurisdiction Code
                                        .address-line {{ lookup.ColoradoJurisdictionCode }}

                                .col-md.mt-3.mt-md-0
                                    .address-container
                                        .address-label Input Address
                                        .address-line {{ lookup.InputAddress.Addr }}
                            .row
                                .col-md-4
                                    router-link.btn.btn-primary-green.font-weight-bold.mt-3.d-print-none(to="/lookup")
                                        font-awesome-icon.mr-2(:icon="['far','plus-circle']")
                                        | NEW ADDRESS
                                //.col-md(v-if="lookup.State == 'Colorado'")
                                //    a(href="https://dealr.cloud/tax-automation?utm_source=dealrtax", target="_blank").dealrcloud-display.d-print-none
                                //        .row.justify-content-center.align-items-center
                                //            .col-auto
                                //                a(href="https://dealr.cloud/tax-automation?utm_source=dealrtax", target="_blank")
                                //                    img(src='../assets/dealrcloud-dark.svg', height='40px', alt='dealr.cloud Logo')
                                //            .col
                                //                h3.my-0 Understand the new tax receipt?
                                //                h4.my-0.text-teal  CALL FOR HELP 720.772.7706
                        .row
                            .col-lg.mb-4
                                .alert-danger.rounded.p-3.mb-3(v-if="lookup.Notes.length")
                                    div(v-for="note in lookup.Notes") {{note}}
                                div(v-if="$store.state.auth.authenticated")
                                    .form-group
                                        label(for="taxableAmount") Taxable Amount
                                        vue-numeric#taxableAmount.form-control(type="text", minus=false, currency="$", :precision=2, v-model="lookup.TaxableAmount")
                                    .form-group
                                        label(for="reference") Reference
                                        input#reference.form-control(type="text", placeholder="Name, stock, order...", v-model="lookup.Reference")
                                    button.btn.btn-primary-green.font-weight-bold.mt-3.d-print-none(v-on:click="initiatePrint()", google-track="print")
                                        font-awesome-icon.mr-2(:icon="['fas','print']")
                                        | PRINT
                                div(v-if="!$store.state.auth.authenticated")
                                    p
                                        | #[strong Sign up for an account to]
                                        ul
                                            li Calculate tax amounts
                                            li Record references
                                            li Print rates
                                            li and more!
                                    router-link.font-weight-bold.btn.btn-primary-green.mt-4(dealr-track-click='ButtonInteractions,TryForFree', to='/try-free')
                                        | Try For Free
                                        font-awesome-icon.ml-1(:icon="['fas','arrow-alt-right']", fixed-width)
                            .col-md.position-relative
                                .sales-tax-container.h-100
                                    +displayTaxes('SALES TAX', 'Rate', 'TotalSalesTaxRate', 'Amount', 'TotalSalesTaxAmount')
                            .col-md.position-relative.mt-5.mt-md-0
                                .sales-tax-container.h-100
                                    div(v-if="!lookup.NeedsUpgradeForUseTaxes")
                                        .auto-use-tax(v-if="!$store.state.auth.settings.BuildingUse")
                                            +displayTaxes('AUTO USE TAX', 'AutoUse', 'TotalAutoUseTaxRate', 'AutoUseAmount', 'TotalAutoUseTaxAmount')
                                        .building-use-tax(v-else)
                                            +displayTaxes('BUILDING MATERIAL<br> USE TAX', 'BuildingUse', 'TotalBuildingUseTaxRate', 'BuildingUseAmount', 'TotalBuildingUseTaxAmount')
                                    .d-flex.flex-column.align-items-start.h-100(v-if="lookup.NeedsUpgradeForUseTaxes === true")
                                        .fade-out-taxes.mb-auto.w-100
                                            .auto-use-tax
                                                +displayTaxes('AUTO USE TAX', 'AutoUse', null, 'AutoUseAmount')
                                            .building-use-tax
                                                +displayTaxes('BUILDING MATERIAL<br> USE TAX', 'BuildingUse', null, 'AutoUseAmount')
                                        .text-center.w-100(v-if="!$store.state.auth.authenticated")
                                            h4.font-weight-black.mb-4 Sign up to view#[br]use tax rates.
                                            router-link.font-weight-bold.btn.btn-primary-green(dealr-track-click='ButtonInteractions,TryForFree', to='/try-free')
                                                | Try For Free
                                                font-awesome-icon.ml-1(:icon="['fas','arrow-alt-right']", fixed-width)
                                        .text-center.w-100(v-if="$store.state.auth.authenticated")
                                            h4.font-weight-black.mb-4 Upgrade your account#[br]to view use tax rates.
                                            router-link.font-weight-bold.btn.btn-primary-green(dealr-track-click='ButtonInteractions,UpgradeAccount', to='/billing')
                                                | Upgrade
                                                font-awesome-icon.ml-1(:icon="['fas','arrow-alt-up']", fixed-width)
                        div.mt-5(v-if="lookup.State == 'Colorado' && lookup.SalesTaxReceiptInfo && $store.state.auth.authenticated").d-print-none
                            .tax-receipt-panel
                                .dealrcloud-panel
                                    .dealrcloud-logo
                                        a(href="https://dealr.cloud/tax-automation?utm_source=dealrtax", target="_blank")
                                            img(src='../assets/dealrcloud-dark.svg', height='40px', alt='dealr.cloud Logo')
                                        h4.header-text.ml-4 Colorado Taxes - DR0024
                                    //
                                    .receipt-printout-panel(v-if="!$store.state.auth.planDetails.SalesTaxReceipt")
                                        .row.justify-content-center.align-items-center
                                            .col-md
                                                h3 Print Colorado Sales Tax Receipt
                                                p
                                                    | Dealr.tax partners with #[a(href="https://dealr.cloud/tax-automation?utm_source=dealrtax", target="_blank") dealr.cloud] to automatically generate and print the
                                                    |
                                                    a(href="https://www.colorado.gov/pacific/sites/default/files/DR0024_2019.pdf", target="_blank") CO DR0024 Standard Sales Tax Receipt
                                                    |.
                                                    | Using our advanced programming and industry expertise to evaluate your physical location and the customer's address we can accurately calculate and fill the form with all necessary information.
                                                p For more information on how to automate your tax processes give us a call at 720.772.7706.
                                            .col-md-4.text-center
                                                router-link.font-weight-bold.btn.btn-lg.btn-primary-green(dealr-track-click='ButtonInteractions,UpgradeAccount', to='/update-plan')
                                                    | Upgrade
                                                    font-awesome-icon.ml-1(:icon="['fas','arrow-alt-up']", fixed-width)
                                    .receipt-printout-panel(v-if="$store.state.auth.planDetails.SalesTaxReceipt")
                                        .alert.alert-danger(v-if="lookup.MissingDealershipInfo")
                                            h5 Missing Dealer Address
                                            p.mb-0 The dealership address is missing or incomplete in settings. #[router-link(to="/settings") Click here] to update it.
                                        .row
                                            .col-lg
                                                .form-group
                                                    label(for="PurchaserName") Purchaser's Name
                                                    input#PurchaserName.form-control(type="text", v-model="lookup.SalesTaxReceiptInfo.PurchaserName")
                                            //.col-lg
                                            //    .form-group
                                            //        label Deal Type
                                            //        b-form-radio-group(v-model="lookup.SalesTaxReceiptInfo.DealType")
                                            //            b-form-radio(value="cash") Cash
                                            //            b-form-radio(value="financed") Financed

                                            .col-lg
                                                .form-group
                                                    label Delivered to Purchaser
                                                    b-form-radio-group(v-model="lookup.SalesTaxReceiptInfo.Delivered")
                                                        b-form-radio(value='1') Yes
                                                        b-form-radio(value='0') No
                                                .alert.alert-danger(v-if="lookup.SalesTaxReceiptInfo.Delivered === '1'")
                                                    | Marking as <strong>Delivered</strong> has major impacts when reporting sales taxes to the state on the DR0100 (monthly report) and requires you to submit multiple DR0100s individually. Be very careful when marking this, and be sure to check with your in-house title clerk and dealer associations for training.
                                        .row
                                            .col-lg
                                                .form-group
                                                    label(for="PurchaserAddress") Purchaser's Address
                                                    input#PurchaserAddress.form-control(type="text", v-model="lookup.InputAddress.Addr", disabled="true")
                                        .row
                                            .col-lg
                                                .form-group
                                                    label(for="grossSalesPrice") Gross Sales Price
                                                    vue-numeric#grossSalesPrice.form-control(type="text", minus=false, currency="$", :precision=2, v-model="lookup.SalesTaxReceiptInfo.GrossSalesPrice")
                                            .col-lg
                                                .form-group
                                                    label(for="grossTradeIn") Gross Trade-in
                                                    vue-numeric#grossTradeIn.form-control(type="text", minus=false, currency="$", :precision=2, v-model="lookup.SalesTaxReceiptInfo.GrossTradeIn")
                                            .col-lg
                                                .form-group
                                                    label(for="dateOfSale") Date of Sale
                                                    datepicker#dateOfSale(v-model="lookup.SalesTaxReceiptInfo.DateOfSale", input-class="form-control", format="MMM d, yyyy")
                                        .row
                                            .col-lg
                                                .form-group
                                                    label(for="VehicleVin") Vehicle VIN
                                                        img.ml-1(v-if="lookup.SalesTaxReceiptInfo.Vehicle.Decoding", src="../assets/green-loader.svg", height="20px")
                                                    input#VehicleVin.form-control(type="text", v-model="lookup.SalesTaxReceiptInfo.Vehicle.Vin", v-on:input="lookupVin(lookup.SalesTaxReceiptInfo.Vehicle)")
                                            .col-lg-2
                                                .form-group
                                                    label(for="VehicleYear") Vehicle Year
                                                    input#VehicleYear.form-control(type="text", v-model="lookup.SalesTaxReceiptInfo.Vehicle.Year")
                                            .col-lg
                                                .form-group
                                                    label(for="VehicleMake") Vehicle Make
                                                    input#VehicleMake.form-control(type="text", v-model="lookup.SalesTaxReceiptInfo.Vehicle.Make")
                                            .col-lg
                                                .form-group
                                                    label(for="VehicleModel") Vehicle Model
                                                    input#VehicleModel.form-control(type="text", v-model="lookup.SalesTaxReceiptInfo.Vehicle.Model")
                                        response-container(:error="lookup.SalesTaxReceiptInfo.Vehicle.DecodeError")

                                        .row
                                            .col-lg
                                                .form-group
                                                    label(for="Trade1Vin") Trade VIN
                                                        img.ml-1(v-if="lookup.SalesTaxReceiptInfo.Trade1.Decoding", src="../assets/green-loader.svg", height="20px")
                                                    input#Trade1Vin.form-control(type="text", v-model="lookup.SalesTaxReceiptInfo.Trade1.Vin", v-on:input="lookupVin(lookup.SalesTaxReceiptInfo.Trade1)")
                                            .col-lg-2
                                                .form-group
                                                    label(for="Trade1Year") Trade Year
                                                    input#Trade1Year.form-control(type="text", v-model="lookup.SalesTaxReceiptInfo.Trade1.Year")
                                            .col-lg
                                                .form-group
                                                    label(for="Trade1Make") Trade Make
                                                    input#Trade1Make.form-control(type="text", v-model="lookup.SalesTaxReceiptInfo.Trade1.Make")
                                            .col-lg
                                                .form-group
                                                    label(for="Trade1Model") Trade Model
                                                    input#Trade1Model.form-control(type="text", v-model="lookup.SalesTaxReceiptInfo.Trade1.Model")
                                        response-container(:error="lookup.SalesTaxReceiptInfo.Trade1.DecodeError")
                                        //.row
                                            .col-lg
                                                .form-group
                                                    label(for="Trade2Vin") Trade 2 VIN
                                                        img.ml-1(v-if="lookup.SalesTaxReceiptInfo.Trade2.Decoding", src="../assets/green-loader.svg", height="20px")
                                                    input#Trade2Vin.form-control(type="text", v-model="lookup.SalesTaxReceiptInfo.Trade2.Vin", v-on:input="lookupVin(lookup.SalesTaxReceiptInfo.Trade2)")
                                            .col-lg-2
                                                .form-group
                                                    label(for="Trade2Year") Trade 2 Year
                                                    input#Trade2Year.form-control(type="text", v-model="lookup.SalesTaxReceiptInfo.Trade2.Year")
                                            .col-lg
                                                .form-group
                                                    label(for="Trade2Make") Trade 2 Make
                                                    input#Trade2Make.form-control(type="text", v-model="lookup.SalesTaxReceiptInfo.Trade2.Make")
                                            .col-lg
                                                .form-group
                                                    label(for="Trade2Model") Trade 2 Model
                                                    input#Trade2Model.form-control(type="text", v-model="lookup.SalesTaxReceiptInfo.Trade2.Model")
                                        //response-container(:error="lookup.SalesTaxReceiptInfo.Trade2.DecodeError")
                                        .print-panel.text-right(v-if="!lookup.MissingDealershipInfo")
                                            button.btn.btn-primary-teal.font-weight-bold.mb-3.d-print-none(v-on:click="printSalesTaxReceipt", google-track="printTaxReceipt", v-button-loading="printingTaxReceipt")
                                                font-awesome-icon.mr-2(:icon="['fas','print']")
                                                | PRINT DR0024
                                    .upsell-panel.mt-4.mb-4.mx-3(v-if="$store.state.auth.planDetails.SalesTaxReceipt")
                                        h4 Tired of re-entering deal information? Call us at 720.772.7706
                                    .upsell-panel.mt-4.mb-4.mx-3(v-if="!$store.state.auth.planDetails.SalesTaxReceipt")
                                        h4 Call us at 720.772.7706 to automate your tax processes.
                        div.mt-5.d-print-none
                            | {{ lookup.RunTime | moment('calendar') }} by {{lookup.User ? lookup.User.Name : 'Anonymous'}}
                        div.mt-5.d-print-block.d-none
                            | {{ lookup.RunTime | moment('M/D/YYYY \\a\\t h:mm a') }} by {{lookup.User ? lookup.User.Name : 'Anonymous'}}
</template>

<script>
    import VueNumeric from '@/components/VueNumeric'
    import UsageIndicator from '../components/UsageIndicator'
    import _ from 'lodash';
    import Datepicker from 'vuejs-datepicker';
    import ResponseContainer from '@/components/ResponseContainer';
    import { BFormRadioGroup as bFormRadioGroup, BFormRadio as bFormRadio } from 'bootstrap-vue'

    export default {
        metaInfo () {
            return {
                title: this.pageTitle,
            }
        },
        components: {
            VueNumeric,
            UsageIndicator,
            Datepicker,
            ResponseContainer,
            bFormRadioGroup,
            bFormRadio,
        },
        computed: {
            pageTitle () {
                if (!this.lookup || !this.lookup.MatchedAddress || !this.lookup.MatchedAddress.Addr) return '';
                return this.lookup.MatchedAddress.Addr;
            },
            lookupCalculated () {
                if (!this.lookup) return null;
                const ret = _.cloneDeep(this.lookup);
                const jurisdictions = ret.Jurisdictions;
                if (ret.TotalSalesTaxRate && ret.TaxableAmount) {
                    ret.TotalSalesTaxAmount = 0;
                    jurisdictions.forEach((j) => {
                        const am = Math.round(this.lookup.TaxableAmount * j.Rate) / 100;
                        j.Amount = this.$root.formatPrice(am);
                        ret.TotalSalesTaxAmount += am;
                    });
                    ret.TotalSalesTaxAmount = this.$root.formatPrice(ret.TotalSalesTaxAmount);
                }
                if (ret.TotalAutoUseTaxRate && ret.TaxableAmount) {
                    ret.TotalAutoUseTaxAmount = 0;
                    jurisdictions.forEach((j) => {
                        const am = Math.round(this.lookup.TaxableAmount * j.AutoUse) / 100;
                        j.AutoUseAmount = this.$root.formatPrice(am);
                        ret.TotalAutoUseTaxAmount += am;
                    });
                    ret.TotalAutoUseTaxAmount = this.$root.formatPrice(ret.TotalAutoUseTaxAmount);
                }
                if (ret.TotalBuildingUseTaxRate && ret.TaxableAmount) {
                    ret.TotalBuildingUseTaxAmount = 0;
                    jurisdictions.forEach((j) => {
                        const am = Math.round(this.lookup.TaxableAmount * j.BuildingUse) / 100;
                        j.BuildingUseAmount = this.$root.formatPrice(am);
                        ret.TotalBuildingUseTaxAmount += am;
                    });
                    ret.TotalBuildingUseTaxAmount = this.$root.formatPrice(ret.TotalBuildingUseTaxAmount);
                }
                ret.jurisdictions = jurisdictions;
                return ret;
            },
        },
        data () {
            return {
                loading: false,
                lookup: null,
                pendingUpdate: false,
                printingTaxReceipt: false,
                error: null,
                Usage: null,
                debounceUpdateLookup: () => {},
            }
        },
        created () {
            if (this.$route.name !== 'max-lookups-used') {
                this.getLookup();
            }

            this.debounceUpdateLookup = () => {};
        },
        methods: {
            async updateLookup () {
                if (!this.$store.state.auth.authenticated || !this.$route.params.lookupId) return;
                const result = await this.$root.axios.put(`lookup/${this.$route.params.lookupId}`, { TaxableAmount: this.lookup.TaxableAmount, Reference: this.lookup.Reference, SalesTaxReceiptInfo: this.lookup.SalesTaxReceiptInfo });
                this.pendingUpdate = false;
            },
            async getLookup (iteration) {
                if (!iteration) iteration = 0;
                this.loading = true;
                let longTriggered = false;
                try {
                    const result = await this.$root.axios.get(`lookup/${this.$route.params.lookupId}`);
                    if (result.data.Finishing === true) {
                        if (!longTriggered) {
                            longTriggered = true;
                            gtag('event', 'getTaxesLongShown', {
                                event_category: 'event'
                            });
                        }
                        if (iteration > 10) {
                            this.error = 'There was a problem looking up the tax rate. Please try again.';
                            this.loading = false;
                            return;
                        }
                        return setTimeout(() => {
                            this.getLookup(iteration + 1);
                        }, 1000);
                    }
                    if (result.data.Error) {
                        if (new Date(result.data.RunTime) > new Date((new Date().getTime()) - 1000 * 60)) {
                            gtag('event', 'getTaxesError', {
                                event_category: 'event'
                            });
                        }
                        this.error = result.data.Error;
                    } else {
                        if (new Date(result.data.RunTime) > new Date((new Date().getTime()) - 1000 * 60)) {
                            gtag('event', 'getTaxesSucceed', {
                                event_category: 'event'
                            });
                        }
                        this.lookup = result.data;
                        this.debounceUpdateLookup = _.debounce(this.updateLookup, 1000);

                        this.$watch('lookup.Reference', this.debounceUpdateLookup);
                        this.$watch('lookup.TaxableAmount', this.debounceUpdateLookup);
                        this.$watch('lookup.SalesTaxReceiptInfo', () => {
                            this.pendingUpdate = true;
                            this.debounceUpdateLookup();
                        }, { deep: true });
                    }

                    this.loading = false;
                } catch (e) {
                    this.error = e.response.data;
                    this.loading = false;
                }
            },
            initiatePrint () {
                window.print();
            },
            async lookupVin (vehicle) {
                vehicle.DecodeError = null;
                if (vehicle.Vin.length === 17) {
                    vehicle.Decoding = true;
                    let result;
                    try {
                        result = await this.$root.axios.get(`lookup/vinLookup/${vehicle.Vin}`);
                        vehicle.Year = result.data.Year;
                        vehicle.Make = result.data.Make;
                        vehicle.Model = result.data.Model;
                        await this.updateLookup();
                    } catch (e) {
                        vehicle.DecodeError = e.response.data;
                    }
                    vehicle.Decoding = false;
                }
                vehicle.Decoding = false;
            },
            async printSalesTaxReceipt () {
                if (this.pendingUpdate) {
                    this.printingTaxReceipt = true;
                    await this.updateLookup();
                }
                printJS({
                    printable: `${process.env.VUE_APP_API_URL}lookup/${this.$route.params.lookupId}/SalesTaxReceipt.pdf?auth=${this.$store.state.auth.origToken}`,
                    onLoadingStart: () => {
                        this.printingTaxReceipt = true;
                    },
                    onLoadingEnd: () => {
                        this.printingTaxReceipt = false;
                    },
                })
            }
        },
    }
</script>

<style lang="scss">
    @import '../scss/RateLookup';
</style>
