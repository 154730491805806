<template lang="pug">
    div
        .mb-5
            .container
                h1.page-title.text-center
                    | Rate Lookup
                .row.justify-content-center.align-items-center
                    .col-lg-8.col-lg-push-2
                        rate-lookup-search.secondary-color
                .row.justify-content-center.mt-5
                    .col-lg-4.col-md-6.position-relative
                        usage-indicator
                .row.justify-content-center.mt-5
                    .col-lg-4.col-md-6.position-relative
                        .sales-tax-container
                            h3.sales-tax-container-label SALES TAX
                            p.text-center Waiting for address...
                //.row.justify-content-center.mt-5
                //    .col-lg-8.col-md-8.position-relative
                //        a(href="https://dealr.cloud/tax-automation?utm_source=dealrtax", target="_blank").dealrcloud-display.d-print-none
                //            .row.justify-content-center.align-items-center
                //                .col-auto
                //                    a(href="https://dealr.cloud/tax-automation?utm_source=dealrtax", target="_blank")
                //                        img(src='../assets/dealrcloud-dark.svg', height='40px', alt='dealr.cloud Logo')
                //                .col
                //                    h3.my-0 Understand the new tax receipt?
                //                    h4.my-0.text-teal  CALL FOR HELP 720.772.7706
</template>

<script>
    import RateLookupSearch from '@/components/RateLookupSearch.vue'
    import UsageIndicator from '../components/UsageIndicator'

    export default {
        metaInfo: {
            title: 'Sales Tax Rate Lookup',
            meta: [
                {
                    name: 'description',
                    content: 'Give us an address, we give you tax rates - simple as that!'
                }
            ],
        },
        components: {
            RateLookupSearch,
            UsageIndicator,
        },
    }
</script>

<style lang="scss">
    @import '../scss/RateLookup';
</style>
