<template lang="pug">
    .container.my-5
        h1 How Auto Taxes Work in Colorado
        .row
            .col-lg-6
                p You have to do taxes, dealr.cloud wants to help! This article was designed to help you navigate the complexities of collecting and filing taxes in Colorado. We hope this gives you a good foundation, but if you don’t want to deal with all of this on your own, dealr.cloud can help with that too.
                p Dealr.cloud is a powerful dealership management system that automates many processes we have in Colorado. Read this article to learn more about how taxes work in Colorado and get automated with dealr.cloud.
            .col-lg-6
                h3 DEALR.CLOUD MAKES TAXES EASY
                ul
                    li Easy sales tax reporting
                    li Automatic sales/use tax determination
                    li Pull taxes quickly
                    li Address-level auto use tax rates
                    li Integrate into the DMS

        //.row.justify-content-center.mb-5
        //    .col-lg-8.col-md-8.position-relative
        //        a(href="https://dealr.cloud/tax-automation?utm_source=dealrtax", target="_blank").dealrcloud-display.d-print-none
        //            .row.justify-content-center.align-items-center
        //                .col-auto
        //                    a(href="https://dealr.cloud/tax-automation?utm_source=dealrtax", target="_blank")
        //                        img(src='@/assets/dealrcloud-dark.svg', height='40px', alt='dealr.cloud Logo')
        //                .col
        //                    h3.my-0 Understand the new CO tax receipt?
        //                    h4.my-0.text-teal  CALL FOR HELP 720.772.7706
        h3 TAX EXAMPLE
        img(width="100%", src="@/assets/tax-help/tax-example-1.png", alt="Tax Example")
        h2 EXPLANATION
        p Colorado has a bizarre and confusing sales tax collection system and in this guide we’re going to walk you through how it works and how to better automate your processes! For the purposes of this article we’re going to use the address 6601 W Colfax Ave, Lakewood, CO 80214 which results in the below return from dealr.tax.
        .row
            .col-lg
                h3 Sales Tax Basics
                p Each business is located within a certain set of jurisdictions. For example, if your dealership was located at the Lakewood address above your tax rates would be the ones under the Sales Tax header in the example to the right. Any business in these jurisdictions would be expected to collect sales tax on items they sell in the area. For example, if you owned a retail store selling books at the Lakewood address you would charge 7.5% tax on each sale.
                h3 Dealership Taxes
                p Taxes for auto dealers get a little trickier because we collect taxes based on a couple of different factors. Typically this is the difference between a cash vs. financed deal; when it’s a cash deal, the customer will most often register the vehicle and pay any taxes the dealership didn’t collect; when it’s a financed deal, a lien must be filed by the bank or dealership. In order to file a lien the DMV must receive all taxes for the customer’s physical location.
                p For the purposes of this document we will show you examples of Cash vs. Financed deals, but know that the rules technically apply to the difference of who (dealership or customer) is titling/registering/filing lien on the vehicle.
            .col-lg
                img(width="100%", src="@/assets/tax-help/tax-example-c.png", alt="Tax Example C")
                .confused-panel.mt-4.mb-4
                    h3.mb-0.mt-0 #[strong Confused?] Simplify your tax process!#[br]Call us at 720.772.7706
        h3 Home Rule Cities
        p Home Rule Cities can define their own tax rules and manage their own tax collection. This means that, instead of remitting your taxes to the state, you have to remit taxes directly to the city and obtain a city tax ID. Denver and Broomfield are just a couple of the cities that are Home Rule Cities in Colorado. You can find the complete list inside of Colorado’s DR1002 form, or, if you are using dealr.cloud, the system will automatically identify Home Rule Cities and their taxes for you.
        h3 Sales vs Use Tax
        p Short: Sales tax is collected for the jurisdiction(s) where you purchase an item, use tax is collected for the jurisdiction(s) where you use an item. You collect sales tax for shared jurisdictions (both customer and dealership in same jurisdiction) and use tax for others (when only the customer is in a jurisdiction). For cash deals use tax does not need to be collected.
        p Long: In the case of vehicle sales, taxes are collected for the jurisdiction(s) where the customer lives. For the Lakewood address example above, if the customer lives next door to the dealership and shares all the same jurisdictions as the dealership, then Sales Tax is collected for all jurisdictions. If the customer lives in Colorado Springs, then Use Tax for the customer’s address is collected only for jurisdictions the dealership doesn’t share with the customer.
        .row
            .col-lg
                p This is tricky when you start looking at a dealership and customer that share some of the jurisdictions but not all. For example, here is the tax lookup for a customer who lives at 7580 W 64th Ave, Arvada, CO 80003 and purchased a car in Lakewood.
                p In this case we’d collect Colorado, Jefferson, RTD, and Science District for a cash deal; for a financed deal we also collect Arvada Use Tax.
                p Most jurisdictions have the same sales and use tax rates, but some (like Adams or Jefferson county) have different rates and it’s important to collect Sales Tax when you share that jurisdiction with the customer and Use Tax when you don’t.
            .col-lg
                img(width="100%", src="@/assets/tax-help/tax-example-d.png", alt="Tax Example D")
        h2.mt-5 MORE EXAMPLES
        img(width="100%", src="@/assets/tax-help/tax-example-2.png", alt="Tax Example D")
    // include:highlight-markdown-it() HowColoradoAutoTaxesWork.md
</template>

<script>
    export default {
        metaInfo: {
            title: 'How Colorado Taxes Work - Auto Dealers',
            meta: [
                {
                    name: 'description',
                    content: 'Learn how Colorado taxes work for auto dealers and how dealr.tax helps automate your business.'
                }
            ],
        },
    }
</script>

<style lang="scss">
    @import '../../scss/Main';
    .confused-panel {
        border-radius: 10px;
        background-color: #00B6CD;
        color: #C7E8F0;
        text-align: center;
        padding: 10px 20px;
    }
</style>
