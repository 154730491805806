<template lang="pug">
    -
        function camelize(str) {
            return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
                if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
                return index == 0 ? match.toLowerCase() : match.toUpperCase();
            });
        }
    mixin input(title, model, attr)
        .form-group&attributes(attributes)
            label(for=camelize(title)) #{title}
            input.form-control(type="text", id=camelize(title), name=camelize(title), v-model=model)&attributes(attr)
    .container.mb-5
        h1.page-title.text-center
            | Report Problem
        .row.justify-content-center
            .col-lg-7
                p Find an error on a lookup? Fill out the form below with the address and any details you know about what the tax rate was and should have been.
                form(v-on:submit.prevent="sendContactForm", v-if="!success")
                    .row
                        .col-md
                            +input('Name', 'Contact.Name', {placeholder: "Johnny Appleseed"})
                        .col-md
                            +input('Company', 'Contact.Company', {placeholder: "Acme, Inc."})
                    +input('Email', 'Contact.Email', {placeholder: "johnny@acme.com"})
                    .form-group
                        label(for="message") Message
                        textarea.form-control(id="message", name="message", v-model="Contact.Message", placeholder="Details of incorrect lookup and any other info you have...")
                    button.btn.btn-secondary(v-button-loading="sending", type="submit") Send Message
                response-container(:success="success", :error="error")
</template>

<script>
    export default {
        metaInfo: {
            title: 'Report Problem'
        },
        data () {
            return {
                Contact: {},
                success: null,
                error: null,
                sending: false,
            }
        },
        methods: {
            async sendContactForm () {
                if (this.sending !== false) return;
                this.sending = true;
                this.success = null;
                this.error = null;
                try {
                    const result = await this.$root.axios.post('contact/problem', this.Contact);
                    this.success = result.data;
                    this.sending = false;
                } catch (e) {
                    this.sending = false;
                    this.error = e.response.data;
                }
            },
        }
    }
</script>

<style lang="scss">

</style>
