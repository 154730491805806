<template lang="pug">
    #rate-lookup(v-bind:class="{'has-search-results': hasAutocompleteResults && searchQuery}")
        input.form-control.form-control-lg#address-lookup-input(
        type="text"
        placeholder="Find tax rates by address..."
        v-bind:class="{'has-search-results': hasAutocompleteResults && searchQuery}"
        v-model="searchQuery"
        v-on:keyup.enter="getTaxRates"
        )
        button.d-none.d-lg-block.btn.btn-primary-green.font-weight-bold(v-on:click="getTaxRates", v-button-loading="gettingTaxRates", google-track="getTaxes") LOOKUP
        button.d-block.d-lg-none.btn.btn-primary-green.font-weight-bold(v-on:click="getTaxRates", v-button-loading="gettingTaxRates", google-track="getTaxes") GO
        .search-icon
            .d-flex.align-items-center.h-100
                font-awesome-icon(:icon="['fas','search']")
        //
            ul#autocomplete-search-results(v-if="searchResults.length", v-bind:class="{'input-has-focus':focused}")
                li(v-for="item in searchResults")
                    a
                        | {{ item.Title }}
</template>

<script>
    import '../scripts/jquery-global.js';
    import _ from 'lodash'
    import App from '../App.vue'
    // import 'autocomplete.js/dist/autocomplete.jquery.js'

    export default {
        mounted () {
            /*
            $(document).ready(() => {
                $('#address-lookup-input').autocomplete({
                    hint: false,
                    appendTo: '#rate-lookup',
                    autoselect: true,
                    openOnFocus: true,
                    cssClasses: {
                        root: 'dealrtax-autocomplete',
                        prefix: 'dta',
                    },
                }, [
                    {
                        source: async (query, callback) => {
                            if (this.$store.state.auth.authenticated && this.$store.state.auth.plan === 'Free') return callback([]);
                            this.loadingResults = true;
                            try {
                                this.hasAutocompleteError = false;
                                const result = await this.$root.axios.get('lookup/autocomplete', { params: { Query: query } });
                                callback(result.data.slice(0, 5));
                            } catch (e) {
                                this.hasAutocompleteError = true;
                                callback([]);
                            }
                            this.loadingResults = false;
                        },
                        displayKey: 'Text',
                        templates: {
                            suggestion: function (suggestion) {
                                return suggestion.Text;
                            },
                            empty: (query, isEmpty) => {
                                let response = 'No matching addresses were found.'
                                if (this.$store.state.auth.authenticated && this.$store.state.auth.plan === 'Free') {
                                    response = 'Upgrade to a paid plan to use our autocomplete functionality.';
                                }
                                return `<div class="dta-no-result">${response}</div>`;
                            }
                        }
                    }
                ]).on('autocomplete:selected', (event, suggestion, dataset, context) => {
                    console.log(event, suggestion, dataset, context);
                    this.searchQuery = suggestion.Text;
                    this.getTaxRates();
                    gtag('event', 'autocompleteUsed', {
                        event_category: 'engagement'
                    });
                }).on('autocomplete:updated', () => {
                    this.hasAutocompleteResults = $('#rate-lookup').find('.dealrtax-autocomplete .dta-dropdown-menu > div').children().length > 0;
                })
            }); */
        },
        methods: {
            async getTaxRates () {
                // if (!this.searchQuery) return;
                if (this.gettingTaxRates) return;
                this.gettingTaxRates = true;
                gtag('event', 'getTaxesRun', {
                    event_category: 'event'
                });
                try {
                    const result = await this.$root.axios.post('lookup/getRates', { Address: { Addr: this.searchQuery } });
                    this.$router.push({ name: 'lookup-result', params: { lookupId: result.data.Id } })
                } catch (e) {
                    if (e.response.status === 430) {
                        this.$router.push({ name: 'max-lookups-used', query: { Address: this.searchQuery } })
                    } else {
                        this.gettingTaxRates = false;
                        alert(e.response.data);
                    }
                }
            }
        },
        data () {
            return {
                hasAutocompleteResults: false,
                hasAutocompleteError: true,
                loadingResults: false,
                searchQuery: '',
                gettingTaxRates: false,
            }
        }
    }
</script>

<style lang="scss">
    @import '../scss/Main.scss';
    @import '../scss/_mixins.scss';

    $search-dialog-background-color: #FFFFFF !default;
    $search-dialog-background-color-2: darken($search-dialog-background-color, 3) !default;
    $button-padding: 4px;
    $animation-length: .1s;

    %rate-box-shadow {
        -webkit-box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    }

    #rate-lookup {
        position: relative;
        input {
            border-radius: 25px;
            height: 50px;
            padding-left: 2.5em;
            z-index: 5;
            background-color: $search-dialog-background-color;
            @include transition($input-transition, border-radius #{$animation-length} ease-out);
            &:focus {
                @extend %rate-box-shadow;
                border-color: #ced4da;
                &.has-search-results {
                    border-bottom-right-radius: 0 !important;
                    border-bottom-left-radius: 0 !important;
                    border-bottom-color: $search-dialog-background-color;
                }
            }
        }
        button {
            border-radius: 50px;
            position: absolute;
            top: $button-padding;
            right: $button-padding;
            height: calc(100% - #{$button-padding*2});
        }
        @include respond-to(md) {
            button {
                width: 50px;
                padding-left: 0;
                padding-right: 0;
            }
            input {
                padding-left: 1rem;
                padding-right: 55px;
            }
            .search-icon {
                display: none;
            }
        }
        .search-icon {
            position: absolute;
            left: 1em;
            top: 0;
            height: 100%;
            color: $primary-color;
            font-size: 1.25rem;
            i {
                vertical-align: middle;
            }
        }

        .dealrtax-autocomplete {
            width: 100%;
            color:  black;
            z-index:1;
            .dta-input, .dta-hint {
                width: 100%;
            }
            .dta-hint {
                color: #999;
            }
            .dta-dropdown-menu {
                width: 100%;
                .dta-no-result, .dta-suggestion {
                    padding: 10px 15px;
                    padding-left: 2.5em;
                    border-left: 1px solid #ced4da;
                    border-right: 1px solid #ced4da;
                    em {
                        font-weight: bold;
                        font-style: normal;
                    }
                    &:last-child {
                        border-bottom-left-radius: 25px;
                        border-bottom-right-radius: 25px;
                    }
                }
                .dta-suggestion {

                    &:not(.dta-no-result).dta-cursor {
                        background-color: darken($search-dialog-background-color, 10%);
                    }
                    &:not(.dta-no-result):hover {
                        cursor: pointer;
                    }
                }
                -webkit-transform: translateY(-10px);
                -ms-transform: translateY(-10px);
                transform: translateY(-10px);

                &:before {
                    display: block;
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    height: 5px;
                    top: -4px;
                    background-color: $search-dialog-background-color;
                    border-left: 1px solid #ced4da;
                    border-right: 1px solid #ced4da;
                    z-index: 2;
                }
                &:after {
                    display: block;
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    height: 100%;
                    top:0;
                    background-color: $search-dialog-background-color;
                    border: 1px solid #ced4da;
                    @extend %rate-box-shadow;
                    z-index: -2;
                    border-bottom-left-radius: 25px;
                    border-bottom-right-radius: 25px;
                }
                opacity: 0;
                visibility: hidden;
                transition: opacity #{$animation-length} ease-out,visibility #{$animation-length} ease-out,-webkit-transform #{$animation-length} ease-out;
                transition: transform #{$animation-length} ease-out,opacity #{$animation-length} ease-out,visibility #{$animation-length} ease-out;
                transition: transform #{$animation-length} ease-out,opacity #{$animation-length} ease-out,visibility #{$animation-length} ease-out,-webkit-transform #{$animation-length} ease-out;
            }
        }

        &.secondary-color {
            input {
                background-color: $search-dialog-background-color-2;
                &:focus.has-search-results {
                    border-bottom-color: $search-dialog-background-color-2;
                }
            }
            .dealrtax-autocomplete .dta-dropdown-menu {
                .dta-suggestion.dta-cursor {
                    background-color: darken($search-dialog-background-color-2, 10%);
                }
                &:before {
                    background-color: $search-dialog-background-color-2;
                }
                &:after {
                    background-color: $search-dialog-background-color-2;
                }
            }

            &:before {
                background-color: $search-dialog-background-color-2;
            }
        }

        &.has-search-results .dealrtax-autocomplete .dta-dropdown-menu {
            opacity: 1;
            visibility: visible;
            -webkit-transform: translateY(0);
            -ms-transform: translateY(0);
            transform: translateY(0);
        }
    }
</style>
