<template lang="pug">
    .green-bg-page
        .mb-5
            .container
                .row.justify-content-center.mt-5
                    .col-lg-8.content-container
                        .row.justify-content-center
                            .col-md
                                h2.mb-4.font-weight-bold GET STARTED
                                p Try dealr.tax for #[b free] on a 7 day trial.#[br]#[strong No credit card required!]
                                router-link.font-weight-bold.btn.btn-primary-green.mt-2(dealr-track-click='ButtonInteractions,TryForFree', to='/try-free')
                                    | Try For Free
                                    font-awesome-icon.ml-1(:icon="['fas','arrow-alt-right']", fixed-width)
                            .col-md.order-first.order-md-last
                                h2.mb-4.font-weight-bold LOGIN
                                form(v-on:submit.prevent="login")
                                    input#username.form-control.mb-3(type="text", placeholder="Username or Email", v-model="UserName")
                                    input#password.form-control(type="password", placeholder="Password", v-model="Password")
                                    .row.mt-3.text-center.text-md-left
                                        .col-md
                                            router-link(to="/forgot-password", tabindex="-1") Forgot password?
                                        .col-md.mt-3.mt-md-0
                                            .text-md-right
                                                button.font-weight-bold.btn.btn-primary-green(type="submit", v-button-loading="loggingIn")
                                                    | Login
                                                    font-awesome-icon.ml-1(:icon="['fas','arrow-alt-right']", fixed-width)
                                    response-container.mt-4(:error="error")

</template>

<script lang="js">
    export default {
        metaInfo: {
            title: 'Login'
        },
        data () {
            return {
                UserName: '',
                Password: '',
                loggingIn: false,
                error: null
            }
        },
        created () {
            if (this.$store.state.auth.authenticated) {
                this.$router.push({ name: 'home' })
            }
        },
        methods: {
            async login () {
                if (this.loggingIn) return;
                this.loggingIn = true
                this.error = null
                try {
                    const result = await this.$root.axios.post('auth/login', { UserName: this.UserName, Password: this.Password });
                    if (this.$route.query.redirect) {
                        const route = this.$route.query.redirect;
                        setTimeout(() => {
                            this.$router.push({ path: route });
                        }, 200);
                    } else {
                        this.$router.push({ name: 'home' })
                    }
                } catch (e) {
                    this.error = e.response.data
                    // if(e.response.status === 401) this.Password = '';
                    this.loggingIn = false
                }
            }
        }
    }
</script>

<style lang="scss">

</style>
