<template lang="pug">
    div
        .form-group
            label(for=addressLine1) {{addressLabel}}
            input.form-control(type="text", id="addressLine1", name="addressLine1", v-model="address.Line1")
        .form-group
            label(for=addressLine2) Line 2
            input.form-control(type="text", id="addressLine2", name="addressLine2", v-model="address.Line2")
        .row
            .col-md
                .form-group
                    label(for=addressCity) City
                    input.form-control(type="text", id="addressCity", name="addressCity", v-model="address.City")
            .col-md-2
                .form-group
                    label(for=addressState) State
                    input.form-control(type="text", id="addressState", name="addressState", v-model="address.State")
            .col-md-3
                .form-group
                    label(for=addressZip) Zip
                    input.form-control(type="text", id="addressZip", name="addressZip", v-model="address.Zip")
</template>

<script>
    export default {
        props: {
            address: {
                type: Object,
                default () {
                    return {};
                },
            },
            addressLabel: {
                type: String,
                default () {
                    return 'Address';
                }
            }
        }
    }
</script>
