<template lang="pug">
    .green-bg-page
        .mb-5
            .container
                .row.justify-content-center.mt-5
                    .col-md-7.col-lg-5.content-container
                        .row.justify-content-center
                            .col-md
                                h4.font-weight-bold Phone Verification
                                .small.mb-3 In order to avoid misuse of our service we need to verify your phone number.
                                response-container(:error="error", :success="success")
                                form(v-on:submit.prevent="sendCode", v-if="!CodeSent")
                                    .form-group
                                        label Confirmation Method
                                        b-form-radio-group(v-model="ConfirmationType")
                                            b-form-radio(value="sms") By Text
                                            b-form-radio(value="call") By Call
                                    .form-group
                                        label US Phone Number
                                        input.form-control(
                                        v-mask="'(###) ###-####'"
                                        type="tel",
                                        v-model="Phone",
                                        v-autofocus-on-load)
                                    p
                                        | Messaging and data rates may apply.
                                    .text-right
                                        button.font-weight-bold.btn.btn-primary-green(type="submit", v-button-loading="Sending")
                                            span(v-if="ConfirmationType === 'sms'")
                                                | Send Code
                                                font-awesome-icon.ml-2(:icon="['fas','paper-plane']")
                                            span(v-else)
                                                | Call Me
                                                font-awesome-icon.ml-2(:icon="['fas','phone']")
                                form(v-on:submit.prevent="checkCode", v-if="CodeSent")
                                    .form-group
                                        label Code
                                        input.form-control(
                                        v-mask="'######'"
                                        type="tel",
                                        v-model="ConfirmationCode",
                                        v-autofocus-on-load)
                                    .text-right
                                        a.d-block.mb-3(@click.prevent="CodeSent = false; success = false", href="#") Resend code.
                                        button.font-weight-bold.btn.btn-primary-green(type="submit", v-button-loading="Sending")
                                            | Verify
                                            font-awesome-icon.ml-1(:icon="['far','check']", fixed-width)
</template>

<script>
    import ResponseContainer from '@/components/ResponseContainer';
    import { mask } from 'vue-the-mask'
    import { BFormRadioGroup as bFormRadioGroup, BFormRadio as bFormRadio } from 'bootstrap-vue'

    export default {
        metaInfo: {
            title: 'Phone Validation'
        },
        directives: {
            mask,
        },
        components: {
            ResponseContainer,
            bFormRadioGroup,
            bFormRadio,
        },
        data () {
            return {
                ConfirmationType: 'sms',
                Phone: null,
                Sending: false,
                error: null,
                success: null,
                ConfirmationCode: null,
                CodeSent: false,
            }
        },
        methods: {
            async sendCode () {
                try {
                    this.Sending = true;
                    this.error = null;
                    this.success = null;
                    const response = await this.$root.axios.post('auth/signup/phoneVerification', {
                        Phone: this.Phone,
                        VerifyType: this.ConfirmationType,
                    });
                    this.success = response.data;
                    this.CodeSent = true;
                    this.Sending = false;
                } catch (e) {
                    this.error = e.response.data;
                    this.Sending = false;
                }
            },
            async checkCode () {
                try {
                    this.Sending = true;
                    this.error = null;
                    this.success = null;
                    const response = await this.$root.axios.put('auth/signup/phoneVerification', {
                        Phone: this.Phone,
                        VerifyCode: this.ConfirmationCode,
                    });
                    this.success = response.data;
                    setTimeout(() => {
                        this.Sending = false;
                        this.$router.push({ name: 'plan-selection' });
                    }, 1000);
                } catch (e) {
                    this.error = e.response.data;
                    this.Sending = false;
                }
            },
        }
    }
</script>

<style lang="scss">

</style>
