<template lang="pug">
    div(v-if="Usage && (!$store.state.auth.authenticated || $store.state.auth.permissions.Lookup)").d-print-none
        .text-danger.text-uppercase.small(v-if="Usage.Delinquent") #[strong Failed Payment] We haven't been paid on your most recent bill
        .text-danger.text-uppercase.small(v-if="Usage.EntityUsageBlocked") #[strong FACILITY OVERUSAGE] Other people have used up your facility's free usage. Please sign up for a paid account.
        .row
            .col-auto.d-flex.flex-column.align-content-between
                .progress-label-value-container(v-if="Usage.UserAllowed !== Usage.Allowed")
                    .progress-label
                        | User Usage
                .progress-label-value-container
                    .progress-label
                        | Included Usage
                .progress-label-value-container(v-if="Usage.PlanIncluded !== Usage.Allowed")
                    .progress-label
                        | Overage
            .col.d-flex.justify-content-around.flex-column
                .progress-container(v-if="Usage.UserAllowed !== Usage.Allowed")
                    .progress.usage-progress-bar
                        .progress-bar(v-bind:style="{ width: usageBar.user + '%' }", v-bind:class="usageBG.user")
                .progress-container
                    .progress.usage-progress-bar
                        .progress-bar(v-bind:style="{ width: usageBar.company + '%' }", v-bind:class="usageBG.company")
                .progress-container(v-if="Usage.PlanIncluded !== Usage.Allowed")
                    .progress.usage-progress-bar
                        .progress-bar(v-bind:style="{ width: usageBar.overage + '%' }", v-bind:class="usageBG.overage")
            .col-auto.d-flex.justify-content-around.flex-column
                .progress-label-value-container(v-if="Usage.UserAllowed !== Usage.Allowed", v-bind:class="usageColor.user")
                    .progress-value
                        | {{Usage.Available}} of {{Usage.UserAllowed}} left
                .progress-label-value-container(v-bind:class="usageColor.company")
                    .progress-value
                        | {{includedUsageRemaining}} of {{Usage.PlanIncluded}} left
                .progress-label-value-container(v-if="Usage.PlanIncluded !== Usage.Allowed", v-bind:class="usageColor.overage")
                    .progress-value
                        | {{includedMaxUsageRemaining}} of {{Usage.Allowed - Usage.PlanIncluded}} left
        .row.no-ie-flex
            .col
                .period-end-indicator(v-if="Usage.PeriodEnd")
                    | Resets on {{Usage.PeriodEnd | moment('M/D/YYYY')}}
                .period-end-indicator(v-if="!Usage.PeriodEnd")
                    | Resets on a rolling 30 day basis
            .col
                .overage-allowed-after.period-end-indicator(v-if="Usage.OverageAllowedAfterTrial") Overage will be allowed after your trial

</template>

<script>
    export default {
        props: {
            companyOnly: {
                type: Boolean,
                default: false,
            }
        },
        computed: {
            usageBar () {
                const ret = {
                    user: 0,
                    company: 0,
                    overage: 0,
                };

                for (const k of Object.keys(ret)) {
                    switch (k) {
                        case 'user':
                            ret[k] = 100 - 100 * this.Usage.Available / this.Usage.UserAllowed;
                            break;
                        case 'company':
                            if (this.Usage.Usage - this.Usage.PlanIncluded >= this.Usage.PlanIncluded) {
                                ret[k] = 100;
                            } else {
                                ret[k] = 100 * this.Usage.Usage / this.Usage.PlanIncluded;
                            }
                            break;
                        case 'overage':
                            const pastPlanIncluded = this.Usage.Allowed - this.Usage.PlanIncluded;
                            let pastPlanUsed = this.Usage.Usage - this.Usage.PlanIncluded;
                            if (pastPlanUsed < 0) pastPlanUsed = 0;

                            ret[k] = 100 * pastPlanUsed / pastPlanIncluded;
                            break;
                    }
                }

                return ret;
            },
            usageBG () {
                const ret = {
                    user: '',
                    company: '',
                    overage: '',
                };

                for (const k of Object.keys(this.usageBar)) {
                    if (this.usageBar[k] < 75) {
                        ret[k] = 'bg-success';
                    } else if (this.usageBar[k] < 100) {
                        ret[k] = 'bg-warning';
                    } else {
                        ret[k] = 'bg-danger';
                    }
                }

                return ret;
            },
            usageColor () {
                const ret = {
                    user: '',
                    company: '',
                    overage: '',
                };

                for (const k of Object.keys(this.usageBar)) {
                    if (this.usageBar[k] < 75) {
                        ret[k] = 'text-secondary';
                    } else if (this.usageBar[k] < 100) {
                        ret[k] = 'text-secondary';
                    } else {
                        ret[k] = 'text-danger';
                    }
                }

                return ret;
            },
            includedUsageRemaining () {
                const allowedRemaining = this.Usage.PlanIncluded - this.Usage.Usage;
                return allowedRemaining > 0 ? allowedRemaining : 0;
            },
            includedMaxUsageRemaining () {
                if (this.Usage.Usage > this.Usage.PlanIncluded) {
                    return this.Usage.Allowed - this.Usage.PlanIncluded + (this.Usage.PlanIncluded - this.Usage.Usage);
                } else {
                    return this.Usage.Allowed - this.Usage.PlanIncluded;
                }
            },
        },
        created () {
            this.getUsage();
        },
        data () {
            return {
                Usage: null,
            };
        },
        methods: {
            async getUsage () {
                try {
                    const result = await this.$root.axios.get('lookup/getUsage');
                    this.Usage = result.data;
                } catch (e) {
                    this.error = e.response.data;
                }
            },
        }
    }
</script>

<style lang="scss">
    @import '~bootstrap/scss/bootstrap-reboot.scss';
    .progress-label-value-container {
        margin: .3rem 0;
        font-size: .8rem;
        font-weight: bold;
        text-transform: uppercase;
        .progress-label {
            line-height: 1;
        }
        .progress-value {
            line-height: 1;
            text-align: right;
        }
    }
    .period-end-indicator {
        font-size: .8rem;
        font-weight: normal;
        text-transform: uppercase;
        white-space: nowrap;
    }
    .usage-progress-bar {
        height: 7px;
    }
    .progress-container {
        width: 100%;
    }
</style>
