<template>
    <div id="app" class="d-flex flex-column h-100-chrome">
        <dealr-navbar/>
        <router-view class="body w-100 flex-fill position-relative" v-bind:class="{'navbar-padding': !noNavbarPadding}"/>
        <dealr-footer class="w-100"/>
    </div>
</template>

<script>
    import DealrNavbar from './components/Navbar.vue'
    import DealrFooter from './components/Footer.vue'
    import axios from 'axios';
    export default {
        metaInfo () {
            return {
                /* changed (newInfo, addedTags, removedTags) {
                    console.log(newInfo);
                    const setMeta = {};
                    for (let i = 0; i < newInfo.meta; i++) {
                        if (newInfo.meta[i].name) {
                            setMeta[newInfo.meta[i].name] = newInfo.meta[i].content;
                        }
                    }

                    this.$store.dispatch('meta/setMeta', setMeta)
                }, */
                titleTemplate: (titleChunk) => {
                    return titleChunk ? `${titleChunk} - dealr.tax` : 'dealr.tax';
                },
                meta: [
                    {
                        name: 'description',
                        content: 'Quickly calculate taxes by address. dealr.tax is a tax locator designed to output tax rates with only an address.'
                    },
                    {
                        property: 'og:title',
                        content: 'dealr.tax',
                    },
                    {
                        property: 'og:image',
                        content: '/opengraph.png'
                    },
                    {
                        property: 'og:site_name',
                        content: 'dealr.tax'
                    },
                    {
                        property: 'og:type',
                        content: 'website'
                    },
                    {
                        name: 'robots',
                        content: 'index, follow'
                    },
                    {
                        name: 'revisit-after',
                        content: '1 days'
                    },
                    {
                        name: 'author',
                        content: 'Dealr, Inc.'
                    },
                    {
                        name: 'keywords',
                        content: 'sales tax, locator, sales tax locator, use tax, use tax locator, taxes by address, sales tax api, sales tax application programing interface, sales tax dealers, use tax dealers, colorado tax rates'
                    }
                ],
            };
        },
        components: {
            DealrNavbar,
            DealrFooter
        },
        data () {
            return {
                pageTitle: 'dealr.tax',
            }
        },
        created () {
            this.$root.$on('noNavbarPadding', () => {
                this.noNavbarPadding = true;
            });
            this.axios = axios.create({
                baseURL: process.env.VUE_APP_API_URL,
                timeout: 15000,
            });
        },
        computed: {
            pageTitleComputed () {
                return this.pageTitle;
            },
            noNavbarPadding () {
                return this.$route.meta.pageDarkBg;
            },
        }
    }
</script>

<style lang="scss">
  @import './scss/App.scss';
</style>
