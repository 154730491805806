<template lang="pug">
    .green-bg-page
        .mb-5
            .container
                .row.justify-content-center.mt-5
                    .col-md-7.col-lg-5.content-container
                        .row.justify-content-center
                            .col-md
                                h4.mb-3.font-weight-bold 14-Day Free Trial
                                p Create a free dealr.tax account today!
                                response-container(:error="error", :success="success")
                                form(v-on:submit.prevent="signup")
                                    .form-group
                                        input.form-control(
                                        type="email",
                                        placeholder="Email",
                                        v-model="Signup.Email",
                                        @keyup="checkEmailUsernameTaken",
                                        @change="checkEmailUsernameTaken",
                                        v-autofocus-on-load)
                                    div(v-if="EmailUsernameTaken")
                                        .text-danger.mb-2 This email has been taken as a username. Please enter another username for login or #[router-link(to="/login") login to your current account].
                                        .form-group
                                            input.form-control(
                                            type="text",
                                            placeholder="Username",
                                            v-model="Signup.UserName",
                                            @keyup="checkUsernameTaken",
                                            @change="checkUsernameTaken")
                                            .text-danger.mt-2(v-if="UsernameTaken") This username has been taken.
                                    .form-group
                                        input.form-control.mb-3(
                                        type="password",
                                        placeholder="Password",
                                        v-model="Signup.Password",
                                        @focus="showPasswordValidator = true",
                                        @blur="showPasswordValidator = false")
                                    ul(v-if="showPasswordValidator")
                                        li(v-for="(valid, string) in Validators", v-bind:class="{'text-danger': !valid, 'text-success': valid}")
                                            | {{string}}
                                    p
                                        | By clicking 'Get Started' you agree to the Dealr, Inc.
                                        |
                                        router-link(to="/terms-of-use") Terms of Use.
                                    .text-right
                                        button.font-weight-bold.btn.btn-primary-green(type="submit", v-button-loading="SigningUp")
                                            | Get Started
                                            font-awesome-icon.ml-1(:icon="['fas','arrow-alt-right']", fixed-width)
</template>

<script>
    import ResponseContainer from '@/components/ResponseContainer';
    // acme@dealr.cloud
    export default {
        metaInfo: {
            title: 'Try Free',
            meta: [
                {
                    name: 'description',
                    content: 'Try dealr.tax free for 14 days, no credit card required!'
                }
            ],
        },
        components: {
            ResponseContainer,
        },
        data () {
            return {
                showPasswordValidator: false,
                error: null,
                success: null,
                Signup: {},
                EmailUsernameTaken: false,
                UsernameTaken: false,
                SigningUp: false,
                Validators: {
                    'Contains a number.': false,
                    'Contains a letter.': false,
                    '8 characters long.': false,
                },
            }
        },
        watch: {
            'Signup.Password' (newPass, oldPass) {
                this.Validators['8 characters long.'] = newPass.length >= 8;
                this.Validators['Contains a number.'] = /\d/.test(newPass);
                this.Validators['Contains a letter.'] = /[a-zA-Z]/.test(newPass);
            },
        },
        created () {

        },
        methods: {
            async checkEmailUsernameTaken () {
                if (!this.Signup.Email) return;
                try {
                    const result = await this.$root.axios.get('auth/usernameExists', { params: { UserName: this.Signup.Email } });
                    this.EmailUsernameTaken = result.data.Taken;
                } catch (e) {

                }
            },
            async checkUsernameTaken () {
                if (!this.Signup.UserName) return;
                try {
                    const result = await this.$root.axios.get('auth/usernameExists', { params: { UserName: this.Signup.UserName } });
                    this.UsernameTaken = result.data.Taken;
                } catch (e) {

                }
            },
            async signup () {
                this.SigningUp = true;
                try {
                    this.error = null;
                    const request = {
                        Email: this.Signup.Email,
                        Password: this.Signup.Password,
                    };
                    if (this.EmailUsernameTaken) {
                        request.UserName = this.Signup.UserName;
                    } else {
                        request.UserName = this.Signup.Email;
                    }
                    const result = await this.$root.axios.post('auth/signup', request);
                    this.$cookies.set('DealrPlanSelection', this.$route.query.plan)
                    this.$router.push({ name: 'plan-selection' });
                    // this.$router.push({ name: 'phone-verification' });
                    this.SigningUp = false;
                } catch (e) {
                    this.error = e.response.data;
                    this.SigningUp = false;
                }
            }
        }
    }
</script>

<style>

</style>
