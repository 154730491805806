<template lang="pug">
    - var rootPath = '@/assets/card-icons/';
    .current-payment-method
        .uppercase-label Payment Method

        div(v-if="paymentMethod.Type === 'invoiced'")
            .card-name
                | Invoice Billing
        div(v-else)
            .card-name
                |{{paymentMethod.NameOnCard}}, {{paymentMethod.Zip}}
            .row.align-items-center
                .col-auto
                    img.card-icon(v-if="paymentMethod.Brand === 'Visa'", src=rootPath + "visa.png")
                    img.card-icon(v-else-if="paymentMethod.Brand === 'American Express'", src=rootPath + "amex.png")
                    img.card-icon(v-else-if="paymentMethod.Brand === 'MasterCard'", src=rootPath + "mastercard.png")
                    img.card-icon(v-else-if="paymentMethod.Brand === 'Discover'", src=rootPath + "discover.png")
                    font-awesome-icon.card-icon(v-else, :icon="['fas','credit-card']", fixed-width)
                .col-auto
                    .card-ending
                        |Ending in {{paymentMethod.Last4}}
                    .card-expiration
                        |Expires {{paymentMethod.ExpMonth + '/' + paymentMethod.ExpYear}}

</template>

<script>
    export default {
        props: {
            paymentMethod: {
                type: Object,
                default () {
                    return {}
                },
            },
        },
    }
</script>

<style lang="scss">
    .card-icon {
        height: 2rem;
        width: auto;
        font-size: 2rem;
    }
    .current-payment-method {
        padding: 1rem;
        .card-name {
            font-size: 1.1rem;
        }
        .card-ending, .card-expiration {
            font-size: .9rem;
            text-transform: uppercase;
        }
    }
</style>
