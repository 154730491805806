<template lang="pug">
    div
        .mb-5
            .container
                .row.page-title-container
                    .col-md
                        h1.page-title-no-margin
                            | Past Lookups
                    .col-md.align-self-center
                        usage-indicator.my-3.my-md-0
                .form-group
                    input#searchQuery.form-control(type="text", placeholder="Reference or user...", v-model="SearchQuery", v-on:keyup="onSearchChange")
                vue-good-table(
                    :columns="columns",
                    :rows="rows",
                    :search-options="SearchOptions",
                    :sort-options="SortOptions",
                    :pagination-options="PaginationOptions",
                    mode="remote",
                    @on-page-change="onPageChange",
                    @on-sort-change="onSortChange",
                    @on-per-page-change="onPerPageChange",
                    @on-row-click="openLookup",
                    :totalRows="totalRecords",
                    :isLoading="isLoading",
                    :showError="error",
                    :sortable=false
                )
                    div(slot="emptystate")
                        | No lookups could be found.
                    div(slot="errorstate")
                        .alert.alert-danger {{error}}
</template>

<script>
    import VueGoodTable from '../components/VueGoodTable/Table';
    import moment from 'moment';
    import axios from 'axios';
    import _ from 'lodash';
    import { mapGetters, mapState } from 'vuex'
    import UsageIndicator from '../components/UsageIndicator'

    // import the styles
    export default {
        metaInfo: {
            title: 'Lookup History'
        },
        created () {
            this.$root.requireAuth();
        },
        computed: {
            ...mapState({
                serverParams: state => state.lookupParams,
            })
        },
        data () {
            const lookupParams = _.cloneDeep(this.$store.state.lookupParams);
            const initialSortBy = [];
            Object.keys(lookupParams.Sort).forEach((s) => {
                initialSortBy.push({ field: s, type: lookupParams.Sort[s] })
            });
            return {
                error: null,
                isLoading: false,
                SearchQuery: lookupParams.Query,
                Reference: null,
                SearchOptions: {
                    enabled: false,
                    placeholder: 'Reference or user...',
                    searchFn: (t) => {
                        // console.log(t);
                    },
                },
                PaginationOptions: {
                    enabled: true,
                    mode: 'pages',
                    perPage: lookupParams.PerPage,
                    setCurrentPage: lookupParams.Page,
                    perPageDropdown: [25, 50, 100],
                    dropdownAllowAll: false,
                    pageText: '',
                },
                SortOptions: {
                    enabled: true,
                    initialSortBy,
                },
                columns: [
                    {
                        label: 'Reference',
                        field: 'Reference',
                        sortable: true,
                        html: 1,
                        formatFn (val, rowData) {
                            return `<div class="reference-column-value">${val || 'No reference.'}</div><small class="reference-column-address">${rowData.MatchedAddress.Addr.join('<br>')}</small>`
                        }
                    },
                    {
                        label: 'Taxable Amount',
                        field: 'TaxableAmount',
                        type: 'number',
                        formatFn (amount, decimalCount = 2, decimal = '.', thousands = ',') {
                            if (!amount) return '';
                            decimalCount = Math.abs(decimalCount);
                            decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

                            const negativeSign = amount < 0 ? '-' : '';

                            const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
                            const j = (i.length > 3) ? i.length % 3 : 0;

                            return '$' + negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '');
                        },
                        sortable: true,
                    },
                    {
                        label: 'Lookup Time',
                        field: 'RunTime',
                        type: 'number',
                        formatFn (v) {
                            return moment(v).calendar();
                        },
                        sortable: true,
                    },
                    {
                        label: 'User',
                        field: 'User.Name',
                        sortable: true,
                    },
                ],
                rows: [

                ],
                totalRecords: 0,
                lastRequest: null,
            }
        },
        components: {
            VueGoodTable,
            UsageIndicator,
        },
        methods: {
            openLookup (params) {
                this.$router.push({ name: 'lookup-result', params: { lookupId: params.row.Id } })
            },
            updateParams (newProps) {
                this.$store.dispatch('lookupParams/updateParams', newProps);
                // this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
            onSearchChange () {
                this.updateParams({ Query: this.SearchQuery });
                this.loadItems();
            },

            onPageChange (params) {
                this.updateParams({ Page: params.currentPage });
                this.loadItems();
            },

            onPerPageChange (params) {
                this.updateParams({ PerPage: params.currentPerPage });
                this.loadItems();
            },

            onSortChange (params) {
                if (params[0]) {
                    this.updateParams({
                        Sort: {
                            [params[0].field]: params[0].type,
                        },
                    });
                } else {
                    this.updateParams({
                        Sort: {},
                    });
                }
                this.loadItems();
            },

            /*
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            }, */

            async loadItems () {
                try {
                    this.isLoading = true;
                    if (this.lastRequest) {
                        this.lastRequest.cancel('Another query has been initiated.');
                    }
                    this.error = null;
                    this.lastRequest = axios.CancelToken.source();
                    const response = await this.$root.axios.get('lookup/', { params: this.serverParams, cancelToken: this.lastRequest.token });
                    this.lastRequest = null;
                    this.rows = response.data.data;
                    this.totalRecords = response.data.totalCount;
                    this.isLoading = false;
                } catch (e) {
                    if (!(e instanceof axios.Cancel)) {
                        if (e.response) {
                            this.error = e.response.data;
                        } else {
                            this.error = 'Server request failed.';
                        }
                        this.isLoading = false;
                    }
                }
            }
        }
    }
</script>

<style lang="scss">
    @import '../scss/VueGoodTable';
    .reference-column-value {
        line-height: 1;
        margin-bottom: -.1em;
        margin-top: .4em;
    }
    .reference-column-address{
        line-height: 1.4;
        display: block;
        margin-top: .4rem;
    }
</style>
