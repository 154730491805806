<template lang="pug">
    -
        function camelize(str) {
            return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
                if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
                return index == 0 ? match.toLowerCase() : match.toUpperCase();
            });
        };
    mixin input(title, model, attr)
        .form-group&attributes(attributes)
            label(for=camelize(title)) #{title}
            input.form-control(type="text", id=camelize(title), name=camelize(title), v-model=model)&attributes(attr)
    div
        .mb-5
            .container
                h1.page-title
                    | PROFILE
                response-container(:success="success", :error="error")
                .row(v-if="User")
                    .col-md-4
                        form(v-on:submit.prevent="updateSelf")
                            +input('Email', 'User.Email')
                            +input('Username', 'User.UserName', {disabled: true})(v-if="User.UserName !== OrigUser.Email")
                            +input('First Name', 'User.NameFirst')
                            +input('Last Name', 'User.NameLast')
                            button.btn.btn-secondary(v-button-loading="updating", type="submit") Update
                    .col-md-4
                        button.btn.btn-secondary(v-if="!changePassword", v-on:click="changePassword = true") Change Password
                        div(v-if="changePassword")
                            h5 CHANGE PASSWORD
                            form(v-on:submit.prevent="updatePassword")
                                +input('Current Password', 'PasswordUpdate.CurrentPassword', {type: 'password'})
                                +input('New Password', 'PasswordUpdate.NewPassword', {type: 'password'})
                                ul
                                    li(v-for="(valid, string) in Validators", v-bind:class="{'text-danger': !valid, 'text-success': valid}")
                                        | {{string}}
                                +input('Confirm Password', 'PasswordUpdate.ConfirmPassword', {type: 'password'})
                                button.btn.btn-secondary(v-button-loading="changingPassword", type="submit") Update Password
</template>

<script>
    import ResponseContainer from '@/components/ResponseContainer';
    import _ from 'lodash';

    export default {
        metaInfo: {
            title: 'User Profile'
        },
        components: { ResponseContainer },
        created () {
            if (this.$root.requireAuth()) return;
            this.getSelf();
        },
        data () {
            return {
                User: null,
                OrigUser: null,
                updating: false,
                changePassword: false,
                changingPassword: false,
                PasswordUpdate: {},
                Validators: {
                    'Contains a number.': false,
                    'Contains a letter.': false,
                    '8 characters long.': false,
                },
                success: null,
                error: null,
            }
        },
        watch: {
            'PasswordUpdate.NewPassword' (newPass, oldPass) {
                this.Validators['8 characters long.'] = newPass.length >= 8;
                this.Validators['Contains a number.'] = /\d/.test(newPass);
                this.Validators['Contains a letter.'] = /[a-zA-Z]/.test(newPass);
            },
        },
        methods: {
            async getSelf () {
                try {
                    const response = await this.$root.axios.get('user/self');
                    this.User = response.data;
                    this.OrigUser = _.cloneDeep(response.data);
                } catch (e) {
                    this.error = e.response.data;
                }
            },
            async updateSelf () {
                if (this.updating !== false) return;
                this.updating = true;
                this.success = null;
                this.error = null;
                try {
                    const result = await this.$root.axios.put('user/self', this.User);
                    this.User = result.data;
                    this.OrigUser = _.cloneDeep(result.data);

                    this.success = 'Profile details updated.';
                    this.updating = false;
                } catch (e) {
                    this.updating = false;
                    this.error = e.response.data;
                    // if(e.response.status === 401) this.Password = '';
                }
            },
            async updatePassword () {
                if (this.PasswordUpdate.NewPassword !== this.PasswordUpdate.ConfirmPassword) return alert('Passwords don\'t match!');
                if (this.changingPassword !== false) return;
                this.changingPassword = true;
                this.success = null;
                this.error = null;
                try {
                    const result = await this.$root.axios.put('user/self', { Password: this.PasswordUpdate.NewPassword, CurrentPassword: this.PasswordUpdate.CurrentPassword });
                    this.success = 'Your password has been changed!';
                    this.changingPassword = false;
                    this.changePassword = false;
                    this.PasswordUpdate = {};
                } catch (e) {
                    this.changingPassword = false;
                    this.error = e.response.data;
                    // if(e.response.status === 401) this.Password = '';
                }
            }
        }
    }
</script>

<style scoped>

</style>
