<template>
    <div class="container">
        <a href="https://www.iubenda.com/privacy-policy/79507148" class="iubenda-white no-brand iubenda-embed iub-body-embed" title="Privacy Policy">Privacy Policy</a>
        <div id="iubendaScript"></div>
    </div>
</template>

<script>
    export default {
        metaInfo: {
            title: 'Privacy Policy'
        },
        mounted () {
            const s = document.createElement('script');
            const tag = document.getElementById('iubendaScript');
            s.src = 'https://cdn.iubenda.com/iubenda.js';
            tag.parentNode.insertBefore(s, tag);
        }
    }
</script>
