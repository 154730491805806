<template lang="pug">
    .container.my-5
        h1 New DR0024 Sales Tax Receipt
        p Colorado released a new #[a(href="https://www.colorado.gov/pacific/sites/default/files/DR0024_2019.pdf", target="_blank") DR0024 Standard Sales Tax Receipt] on October 24th, 2019, and it's causing problems for a lot of dealers. The new format is a simple change, but without a detailed understanding of how taxes work for auto sales in Colorado, it's very difficult to fill out.
        p We made a guide on #[router-link(to="/how-colorado-auto-taxes-work") How Colorado Auto Taxes Work] to help you navigate the confusing Colorado tax systems and help you fill out the form.
        h5.text-center Automate this process using #[a(href="https://dealr.cloud/tax-automation?utm_source=dealrtax", target="_blank") dealr.cloud], our all-in-one dealership management platform
        .row.justify-content-center.mt-5.mb-5
            .col-lg-8.col-md-8.position-relative
                a(href="https://dealr.cloud/tax-automation?utm_source=dealrtax", target="_blank").dealrcloud-display.d-print-none
                    .row.justify-content-center.align-items-center
                        .col-auto
                            a(href="https://dealr.cloud/tax-automation?utm_source=dealrtax", target="_blank")
                                img(src='@/assets/dealrcloud-dark.svg', height='40px', alt='dealr.cloud Logo')
                        .col
                            h3.my-0 Understand the new tax receipt?
                            h4.my-0.text-teal CALL FOR HELP 720.772.7706
        h2.mt-4 What's Different
        p The main difference on the form is that you are now required to list all taxes collected regardless of if you are remitting them directly to the state on the DR0100 form or to the DMV with the DR0024 form.
        p The likely reason for this change is that the state and the DMV wants to know what taxes were collected by the dealership which are being paid to the DMV (on DR0024), to the state (on DR0100), and to home-rule cities.
        p The breakdown is confusing to calculate because it's not immediately obvious which cities are and are not home-rule, and allocating where the taxes are going is also not straightforward.
        img.my-4.mx-auto.d-block(width="100%", style="max-width: 700px;", src="@/assets/tax-help/sales-tax-receipt.png", alt="DR0024 Sales Tax Receipt")
        h2.mt-4 dealr.tax Solution
        p In order to help dealerships fill out this form correctly, dealr.tax now offers a plan that allows you to print the DR0024 form directly from the rate lookup page. You can upgrade to the new "Dealer" plans and get printing the new DR0024 Sales Tax Receipt today!
        h2 Once You’ve Upgraded
        p With dealr.tax, you won’t have to worry about calculations and filling out the new, confusing form. We’ll do that for you! The new Dealer version of dealr.tax makes your life easier by automatically calculating and filling out the new DR0024 Sales Tax Receipt Form correctly. Dealr.tax and dealr.cloud are the only systems to offer this solution to Colorado dealers.
        h3 Cash vs Financed
        p To simplify the process of filling the DR0024, dealr.tax offers the option of selecting "Cash" or "Financed" when printing the Tax Receipt. When "Cash" is selected dealr.tax assumes you are collecting only in-area tax amounts. When "Financed" is selected dealr.tax assumes you are collecting all tax rates based on the customer's location, and will fill the receipt accordingly.
        // include:highlight-markdown-it() NewDR0024SalesTaxReceipt.md
</template>

<script>
    export default {
        metaInfo: {
            title: 'New DR0024 Sales Tax Receipt Colorado',
            meta: [
                {
                    name: 'description',
                    content: 'Learn about Colorado\'s new DR0024 Standard Sales Tax Receipt and how it changes the way you process deals.'
                }
            ],
        },
    }
</script>
