<template lang="pug">
    #home-hero-header-lookup
        rate-lookup-search
</template>

<script>
    import RateLookupSearch from './RateLookupSearch.vue'

    export default {
        components: {
            RateLookupSearch,
        },
    }
</script>

<style lang="scss">

</style>
